/**
 * @fileoverview gRPC-Web generated client stub for server
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_pb = require('./base_pb.js')
const proto = {};
proto.server = require('./server_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.server.ProxyProviderClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.server.ProxyProviderPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.UserProviderIn,
 *   !proto.server.UserProviderOut>}
 */
const methodDescriptor_ProxyProvider_UserLogin = new grpc.web.MethodDescriptor(
  '/server.ProxyProvider/UserLogin',
  grpc.web.MethodType.UNARY,
  proto.server.UserProviderIn,
  proto.server.UserProviderOut,
  /**
   * @param {!proto.server.UserProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProviderOut.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.server.UserProviderIn,
 *   !proto.server.UserProviderOut>}
 */
const methodInfo_ProxyProvider_UserLogin = new grpc.web.AbstractClientBase.MethodInfo(
  proto.server.UserProviderOut,
  /**
   * @param {!proto.server.UserProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProviderOut.deserializeBinary
);


/**
 * @param {!proto.server.UserProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.server.UserProviderOut)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.UserProviderOut>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ProxyProviderClient.prototype.userLogin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.ProxyProvider/UserLogin',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_UserLogin,
      callback);
};


/**
 * @param {!proto.server.UserProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.UserProviderOut>}
 *     Promise that resolves to the response
 */
proto.server.ProxyProviderPromiseClient.prototype.userLogin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.ProxyProvider/UserLogin',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_UserLogin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.UserProviderIn,
 *   !proto.server.UserProviderOut>}
 */
const methodDescriptor_ProxyProvider_UserLogout2 = new grpc.web.MethodDescriptor(
  '/server.ProxyProvider/UserLogout2',
  grpc.web.MethodType.UNARY,
  proto.server.UserProviderIn,
  proto.server.UserProviderOut,
  /**
   * @param {!proto.server.UserProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProviderOut.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.server.UserProviderIn,
 *   !proto.server.UserProviderOut>}
 */
const methodInfo_ProxyProvider_UserLogout2 = new grpc.web.AbstractClientBase.MethodInfo(
  proto.server.UserProviderOut,
  /**
   * @param {!proto.server.UserProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProviderOut.deserializeBinary
);


/**
 * @param {!proto.server.UserProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.server.UserProviderOut)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.UserProviderOut>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ProxyProviderClient.prototype.userLogout2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.ProxyProvider/UserLogout2',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_UserLogout2,
      callback);
};


/**
 * @param {!proto.server.UserProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.UserProviderOut>}
 *     Promise that resolves to the response
 */
proto.server.ProxyProviderPromiseClient.prototype.userLogout2 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.ProxyProvider/UserLogout2',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_UserLogout2);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.UserProviderIn,
 *   !proto.server.UserProviderOut>}
 */
const methodDescriptor_ProxyProvider_UserLogout = new grpc.web.MethodDescriptor(
  '/server.ProxyProvider/UserLogout',
  grpc.web.MethodType.UNARY,
  proto.server.UserProviderIn,
  proto.server.UserProviderOut,
  /**
   * @param {!proto.server.UserProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProviderOut.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.server.UserProviderIn,
 *   !proto.server.UserProviderOut>}
 */
const methodInfo_ProxyProvider_UserLogout = new grpc.web.AbstractClientBase.MethodInfo(
  proto.server.UserProviderOut,
  /**
   * @param {!proto.server.UserProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProviderOut.deserializeBinary
);


/**
 * @param {!proto.server.UserProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.server.UserProviderOut)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.UserProviderOut>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ProxyProviderClient.prototype.userLogout =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.ProxyProvider/UserLogout',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_UserLogout,
      callback);
};


/**
 * @param {!proto.server.UserProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.UserProviderOut>}
 *     Promise that resolves to the response
 */
proto.server.ProxyProviderPromiseClient.prototype.userLogout =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.ProxyProvider/UserLogout',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_UserLogout);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.UserProviderIn,
 *   !proto.server.UserProviderOut>}
 */
const methodDescriptor_ProxyProvider_ChangeUserPassword = new grpc.web.MethodDescriptor(
  '/server.ProxyProvider/ChangeUserPassword',
  grpc.web.MethodType.UNARY,
  proto.server.UserProviderIn,
  proto.server.UserProviderOut,
  /**
   * @param {!proto.server.UserProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProviderOut.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.server.UserProviderIn,
 *   !proto.server.UserProviderOut>}
 */
const methodInfo_ProxyProvider_ChangeUserPassword = new grpc.web.AbstractClientBase.MethodInfo(
  proto.server.UserProviderOut,
  /**
   * @param {!proto.server.UserProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProviderOut.deserializeBinary
);


/**
 * @param {!proto.server.UserProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.server.UserProviderOut)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.UserProviderOut>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ProxyProviderClient.prototype.changeUserPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.ProxyProvider/ChangeUserPassword',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_ChangeUserPassword,
      callback);
};


/**
 * @param {!proto.server.UserProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.UserProviderOut>}
 *     Promise that resolves to the response
 */
proto.server.ProxyProviderPromiseClient.prototype.changeUserPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.ProxyProvider/ChangeUserPassword',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_ChangeUserPassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.UserProviderIn,
 *   !proto.server.UserProviderOut>}
 */
const methodDescriptor_ProxyProvider_UserRegister = new grpc.web.MethodDescriptor(
  '/server.ProxyProvider/UserRegister',
  grpc.web.MethodType.UNARY,
  proto.server.UserProviderIn,
  proto.server.UserProviderOut,
  /**
   * @param {!proto.server.UserProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProviderOut.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.server.UserProviderIn,
 *   !proto.server.UserProviderOut>}
 */
const methodInfo_ProxyProvider_UserRegister = new grpc.web.AbstractClientBase.MethodInfo(
  proto.server.UserProviderOut,
  /**
   * @param {!proto.server.UserProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProviderOut.deserializeBinary
);


/**
 * @param {!proto.server.UserProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.server.UserProviderOut)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.UserProviderOut>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ProxyProviderClient.prototype.userRegister =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.ProxyProvider/UserRegister',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_UserRegister,
      callback);
};


/**
 * @param {!proto.server.UserProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.UserProviderOut>}
 *     Promise that resolves to the response
 */
proto.server.ProxyProviderPromiseClient.prototype.userRegister =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.ProxyProvider/UserRegister',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_UserRegister);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.UserProviderIn,
 *   !proto.server.UserProviderOut>}
 */
const methodDescriptor_ProxyProvider_GetUserBalance = new grpc.web.MethodDescriptor(
  '/server.ProxyProvider/GetUserBalance',
  grpc.web.MethodType.UNARY,
  proto.server.UserProviderIn,
  proto.server.UserProviderOut,
  /**
   * @param {!proto.server.UserProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProviderOut.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.server.UserProviderIn,
 *   !proto.server.UserProviderOut>}
 */
const methodInfo_ProxyProvider_GetUserBalance = new grpc.web.AbstractClientBase.MethodInfo(
  proto.server.UserProviderOut,
  /**
   * @param {!proto.server.UserProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProviderOut.deserializeBinary
);


/**
 * @param {!proto.server.UserProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.server.UserProviderOut)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.UserProviderOut>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ProxyProviderClient.prototype.getUserBalance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.ProxyProvider/GetUserBalance',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_GetUserBalance,
      callback);
};


/**
 * @param {!proto.server.UserProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.UserProviderOut>}
 *     Promise that resolves to the response
 */
proto.server.ProxyProviderPromiseClient.prototype.getUserBalance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.ProxyProvider/GetUserBalance',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_GetUserBalance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.UserProviderIn,
 *   !proto.server.UserProviderOut>}
 */
const methodDescriptor_ProxyProvider_ChangeUserNickName = new grpc.web.MethodDescriptor(
  '/server.ProxyProvider/ChangeUserNickName',
  grpc.web.MethodType.UNARY,
  proto.server.UserProviderIn,
  proto.server.UserProviderOut,
  /**
   * @param {!proto.server.UserProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProviderOut.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.server.UserProviderIn,
 *   !proto.server.UserProviderOut>}
 */
const methodInfo_ProxyProvider_ChangeUserNickName = new grpc.web.AbstractClientBase.MethodInfo(
  proto.server.UserProviderOut,
  /**
   * @param {!proto.server.UserProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProviderOut.deserializeBinary
);


/**
 * @param {!proto.server.UserProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.server.UserProviderOut)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.UserProviderOut>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ProxyProviderClient.prototype.changeUserNickName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.ProxyProvider/ChangeUserNickName',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_ChangeUserNickName,
      callback);
};


/**
 * @param {!proto.server.UserProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.UserProviderOut>}
 *     Promise that resolves to the response
 */
proto.server.ProxyProviderPromiseClient.prototype.changeUserNickName =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.ProxyProvider/ChangeUserNickName',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_ChangeUserNickName);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.UserProxyConfigProviderIn,
 *   !proto.server.UserProxyConfigProviderOut>}
 */
const methodDescriptor_ProxyProvider_UserProxyConfigClashGet = new grpc.web.MethodDescriptor(
  '/server.ProxyProvider/UserProxyConfigClashGet',
  grpc.web.MethodType.UNARY,
  proto.server.UserProxyConfigProviderIn,
  proto.server.UserProxyConfigProviderOut,
  /**
   * @param {!proto.server.UserProxyConfigProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProxyConfigProviderOut.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.server.UserProxyConfigProviderIn,
 *   !proto.server.UserProxyConfigProviderOut>}
 */
const methodInfo_ProxyProvider_UserProxyConfigClashGet = new grpc.web.AbstractClientBase.MethodInfo(
  proto.server.UserProxyConfigProviderOut,
  /**
   * @param {!proto.server.UserProxyConfigProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProxyConfigProviderOut.deserializeBinary
);


/**
 * @param {!proto.server.UserProxyConfigProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.server.UserProxyConfigProviderOut)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.UserProxyConfigProviderOut>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ProxyProviderClient.prototype.userProxyConfigClashGet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.ProxyProvider/UserProxyConfigClashGet',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_UserProxyConfigClashGet,
      callback);
};


/**
 * @param {!proto.server.UserProxyConfigProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.UserProxyConfigProviderOut>}
 *     Promise that resolves to the response
 */
proto.server.ProxyProviderPromiseClient.prototype.userProxyConfigClashGet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.ProxyProvider/UserProxyConfigClashGet',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_UserProxyConfigClashGet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.ShopProviderIn,
 *   !proto.server.ShopProviderOut>}
 */
const methodDescriptor_ProxyProvider_GetMShop = new grpc.web.MethodDescriptor(
  '/server.ProxyProvider/GetMShop',
  grpc.web.MethodType.UNARY,
  proto.server.ShopProviderIn,
  proto.server.ShopProviderOut,
  /**
   * @param {!proto.server.ShopProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.ShopProviderOut.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.server.ShopProviderIn,
 *   !proto.server.ShopProviderOut>}
 */
const methodInfo_ProxyProvider_GetMShop = new grpc.web.AbstractClientBase.MethodInfo(
  proto.server.ShopProviderOut,
  /**
   * @param {!proto.server.ShopProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.ShopProviderOut.deserializeBinary
);


/**
 * @param {!proto.server.ShopProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.server.ShopProviderOut)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.ShopProviderOut>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ProxyProviderClient.prototype.getMShop =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.ProxyProvider/GetMShop',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_GetMShop,
      callback);
};


/**
 * @param {!proto.server.ShopProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.ShopProviderOut>}
 *     Promise that resolves to the response
 */
proto.server.ProxyProviderPromiseClient.prototype.getMShop =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.ProxyProvider/GetMShop',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_GetMShop);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.ShopProviderIn,
 *   !proto.server.ShopProviderOut>}
 */
const methodDescriptor_ProxyProvider_GetMProduct = new grpc.web.MethodDescriptor(
  '/server.ProxyProvider/GetMProduct',
  grpc.web.MethodType.UNARY,
  proto.server.ShopProviderIn,
  proto.server.ShopProviderOut,
  /**
   * @param {!proto.server.ShopProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.ShopProviderOut.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.server.ShopProviderIn,
 *   !proto.server.ShopProviderOut>}
 */
const methodInfo_ProxyProvider_GetMProduct = new grpc.web.AbstractClientBase.MethodInfo(
  proto.server.ShopProviderOut,
  /**
   * @param {!proto.server.ShopProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.ShopProviderOut.deserializeBinary
);


/**
 * @param {!proto.server.ShopProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.server.ShopProviderOut)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.ShopProviderOut>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ProxyProviderClient.prototype.getMProduct =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.ProxyProvider/GetMProduct',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_GetMProduct,
      callback);
};


/**
 * @param {!proto.server.ShopProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.ShopProviderOut>}
 *     Promise that resolves to the response
 */
proto.server.ProxyProviderPromiseClient.prototype.getMProduct =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.ProxyProvider/GetMProduct',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_GetMProduct);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.UserPaymentIn,
 *   !proto.server.UserPaymentOut>}
 */
const methodDescriptor_ProxyProvider_UserPaymentCheckOut = new grpc.web.MethodDescriptor(
  '/server.ProxyProvider/UserPaymentCheckOut',
  grpc.web.MethodType.UNARY,
  proto.server.UserPaymentIn,
  proto.server.UserPaymentOut,
  /**
   * @param {!proto.server.UserPaymentIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserPaymentOut.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.server.UserPaymentIn,
 *   !proto.server.UserPaymentOut>}
 */
const methodInfo_ProxyProvider_UserPaymentCheckOut = new grpc.web.AbstractClientBase.MethodInfo(
  proto.server.UserPaymentOut,
  /**
   * @param {!proto.server.UserPaymentIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserPaymentOut.deserializeBinary
);


/**
 * @param {!proto.server.UserPaymentIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.server.UserPaymentOut)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.UserPaymentOut>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ProxyProviderClient.prototype.userPaymentCheckOut =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.ProxyProvider/UserPaymentCheckOut',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_UserPaymentCheckOut,
      callback);
};


/**
 * @param {!proto.server.UserPaymentIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.UserPaymentOut>}
 *     Promise that resolves to the response
 */
proto.server.ProxyProviderPromiseClient.prototype.userPaymentCheckOut =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.ProxyProvider/UserPaymentCheckOut',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_UserPaymentCheckOut);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.UpdateProviderIn,
 *   !proto.server.UpdateProviderOut>}
 */
const methodDescriptor_ProxyProvider_CheckUpdate = new grpc.web.MethodDescriptor(
  '/server.ProxyProvider/CheckUpdate',
  grpc.web.MethodType.UNARY,
  proto.server.UpdateProviderIn,
  proto.server.UpdateProviderOut,
  /**
   * @param {!proto.server.UpdateProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UpdateProviderOut.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.server.UpdateProviderIn,
 *   !proto.server.UpdateProviderOut>}
 */
const methodInfo_ProxyProvider_CheckUpdate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.server.UpdateProviderOut,
  /**
   * @param {!proto.server.UpdateProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UpdateProviderOut.deserializeBinary
);


/**
 * @param {!proto.server.UpdateProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.server.UpdateProviderOut)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.UpdateProviderOut>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ProxyProviderClient.prototype.checkUpdate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.ProxyProvider/CheckUpdate',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_CheckUpdate,
      callback);
};


/**
 * @param {!proto.server.UpdateProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.UpdateProviderOut>}
 *     Promise that resolves to the response
 */
proto.server.ProxyProviderPromiseClient.prototype.checkUpdate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.ProxyProvider/CheckUpdate',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_CheckUpdate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.UserProviderIn,
 *   !proto.server.UserProviderOut>}
 */
const methodDescriptor_ProxyProvider_AuthProxy = new grpc.web.MethodDescriptor(
  '/server.ProxyProvider/AuthProxy',
  grpc.web.MethodType.UNARY,
  proto.server.UserProviderIn,
  proto.server.UserProviderOut,
  /**
   * @param {!proto.server.UserProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProviderOut.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.server.UserProviderIn,
 *   !proto.server.UserProviderOut>}
 */
const methodInfo_ProxyProvider_AuthProxy = new grpc.web.AbstractClientBase.MethodInfo(
  proto.server.UserProviderOut,
  /**
   * @param {!proto.server.UserProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProviderOut.deserializeBinary
);


/**
 * @param {!proto.server.UserProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.server.UserProviderOut)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.UserProviderOut>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ProxyProviderClient.prototype.authProxy =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.ProxyProvider/AuthProxy',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_AuthProxy,
      callback);
};


/**
 * @param {!proto.server.UserProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.UserProviderOut>}
 *     Promise that resolves to the response
 */
proto.server.ProxyProviderPromiseClient.prototype.authProxy =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.ProxyProvider/AuthProxy',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_AuthProxy);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.UserProviderIn,
 *   !proto.server.UserProviderOut>}
 */
const methodDescriptor_ProxyProvider_UpdateProxyTraffic = new grpc.web.MethodDescriptor(
  '/server.ProxyProvider/UpdateProxyTraffic',
  grpc.web.MethodType.UNARY,
  proto.server.UserProviderIn,
  proto.server.UserProviderOut,
  /**
   * @param {!proto.server.UserProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProviderOut.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.server.UserProviderIn,
 *   !proto.server.UserProviderOut>}
 */
const methodInfo_ProxyProvider_UpdateProxyTraffic = new grpc.web.AbstractClientBase.MethodInfo(
  proto.server.UserProviderOut,
  /**
   * @param {!proto.server.UserProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProviderOut.deserializeBinary
);


/**
 * @param {!proto.server.UserProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.server.UserProviderOut)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.UserProviderOut>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ProxyProviderClient.prototype.updateProxyTraffic =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.ProxyProvider/UpdateProxyTraffic',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_UpdateProxyTraffic,
      callback);
};


/**
 * @param {!proto.server.UserProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.UserProviderOut>}
 *     Promise that resolves to the response
 */
proto.server.ProxyProviderPromiseClient.prototype.updateProxyTraffic =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.ProxyProvider/UpdateProxyTraffic',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_UpdateProxyTraffic);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.UserProviderIn,
 *   !proto.server.UserProviderOut>}
 */
const methodDescriptor_ProxyProvider_GetUserProxyInfo = new grpc.web.MethodDescriptor(
  '/server.ProxyProvider/GetUserProxyInfo',
  grpc.web.MethodType.UNARY,
  proto.server.UserProviderIn,
  proto.server.UserProviderOut,
  /**
   * @param {!proto.server.UserProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProviderOut.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.server.UserProviderIn,
 *   !proto.server.UserProviderOut>}
 */
const methodInfo_ProxyProvider_GetUserProxyInfo = new grpc.web.AbstractClientBase.MethodInfo(
  proto.server.UserProviderOut,
  /**
   * @param {!proto.server.UserProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UserProviderOut.deserializeBinary
);


/**
 * @param {!proto.server.UserProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.server.UserProviderOut)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.UserProviderOut>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ProxyProviderClient.prototype.getUserProxyInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.ProxyProvider/GetUserProxyInfo',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_GetUserProxyInfo,
      callback);
};


/**
 * @param {!proto.server.UserProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.UserProviderOut>}
 *     Promise that resolves to the response
 */
proto.server.ProxyProviderPromiseClient.prototype.getUserProxyInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.ProxyProvider/GetUserProxyInfo',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_GetUserProxyInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.ProxyProviderIn,
 *   !proto.server.ProxyProviderOut>}
 */
const methodDescriptor_ProxyProvider_ProxyNodeHeartbeat = new grpc.web.MethodDescriptor(
  '/server.ProxyProvider/ProxyNodeHeartbeat',
  grpc.web.MethodType.UNARY,
  proto.server.ProxyProviderIn,
  proto.server.ProxyProviderOut,
  /**
   * @param {!proto.server.ProxyProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.ProxyProviderOut.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.server.ProxyProviderIn,
 *   !proto.server.ProxyProviderOut>}
 */
const methodInfo_ProxyProvider_ProxyNodeHeartbeat = new grpc.web.AbstractClientBase.MethodInfo(
  proto.server.ProxyProviderOut,
  /**
   * @param {!proto.server.ProxyProviderIn} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.ProxyProviderOut.deserializeBinary
);


/**
 * @param {!proto.server.ProxyProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.server.ProxyProviderOut)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.ProxyProviderOut>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ProxyProviderClient.prototype.proxyNodeHeartbeat =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.ProxyProvider/ProxyNodeHeartbeat',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_ProxyNodeHeartbeat,
      callback);
};


/**
 * @param {!proto.server.ProxyProviderIn} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.ProxyProviderOut>}
 *     Promise that resolves to the response
 */
proto.server.ProxyProviderPromiseClient.prototype.proxyNodeHeartbeat =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.ProxyProvider/ProxyNodeHeartbeat',
      request,
      metadata || {},
      methodDescriptor_ProxyProvider_ProxyNodeHeartbeat);
};


module.exports = proto.server;

