import React from 'react';
import common from "../../common/common.js";
import {Index} from '../../component/index/index';



export default {
    HtmlComponent() {
        return <div>
            <Index name="aaa"/>
            <Script></Script>
        </div>
    },
}


function Script() {
    return common.HtmlComponent(` 
    <script src="https://ajax.aspnetcdn.com/ajax/jQuery/jquery-2.1.1.min.js"></script>
    <script>
        window.onscroll = function () {
            myFunction()
        };
    
        var header = document.getElementById("myHeader");
        var sticky = header.offsetTop;
    
        function myFunction() {
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
                $('#header_placeholder').show();
            } else {
                header.classList.remove("sticky");
                $('#header_placeholder').hide();
            }
        }
    </script>
    `
    )
}

