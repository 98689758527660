import { ProxyProviderClient } from "../api_js/server_grpc_web_pb";
import InnerHTML from 'dangerously-set-html-content'
var grpcClient = new ProxyProviderClient(window.location.origin + "/service/api");

export default {
    HtmlComponent(html) {
        return (
            <InnerHTML html={html} />
        )
    },

    client() {
        return grpcClient;
    },

     download(src) {
        var $a = document.createElement('a');
        $a.setAttribute("href", src);
        $a.setAttribute("target", "_blank");
        $a.setAttribute("download", "");
        var evObj = document.createEvent('MouseEvents');
        evObj.initMouseEvent( 'click', false, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        // evObj.initMouseEvent( 'click', true, true, window, 0, 0, 0, 0, 0, false, false, true, false, 0, null);
        $a.dispatchEvent(evObj);
      }
}
