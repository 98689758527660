import React from "react";
import {checkUpdate} from '../../services/index/checkUpdate';

function DownloadAction() {
    function handleClick(e) {
        e.preventDefault();
        checkUpdate();
    }
    return (
        <a href="#" onClick={handleClick}>
            直接下载
        </a>
    );
}

function GetYear() {
    let year=2024;
	let yearDataString = "";
	if (new Date().getFullYear()>year){
		yearDataString="- "+new Date().getFullYear();
	}
	return (<p>© Copyright {year}{yearDataString} Aftme.LLC </p>);
}
/*
<div style={{ textAlign: "center" }}> 

    <h3>-</h3>
    <p />
    <p />
    <button>
            <DownloadAction></DownloadAction>
    </button>

    <button>
*/
export function Index(props) {
    return <>
        <meta charSet="utf-8" />
        <link rel="stylesheet" href="/thirdparty/static/bootstrap.min.css" />
        <link rel="stylesheet" href="/thirdparty/static/w3.css" />
        <link rel="stylesheet" href="/thirdparty/static/style.css" />
        <link href="/thirdparty/static/main.css" rel="stylesheet" />
        <link
            href="/thirdparty/static/css2-family=Montserrat-wght@100;400;600;700&display=swap.css"
            rel="stylesheet"
        />
        <div
            id="myHeader"
            style={{ backgroundColor: "#1491c7", color: "#FEFEFE" }}
        >
            <header className="container">
                <img id="header-logo" src="/thirdparty/static/logo.svg" />
                <div className="nav desktop" id="nav1">
                    首页
                    <button>
                        <a href>社区</a>
                    </button>
                </div>
                <nav role="navigation" className="mobile">
                    <div id="menuToggle">
                        <input type="checkbox" />
                        <span />
                        <span />
                        <span />
                        <ul id="menu">
                            <a href="#">
                                <li>首页</li>
                            </a>
                            <a href="#">
                                <li>社区</li>
                            </a>
                        </ul>
                    </div>
                </nav>
            </header>
        </div>
        <div id="header_placeholder" />
        <div id="section1" className="rellax" data-rellax-speed={-3} style={{ backgroundColor: "#1491c7", color: "#FEFEFE" }}>
            <div className="container">
                <div className="col-md-6">
                    <h1>解决方案供应商</h1>
                    <p>为您提供出色的互联网产品解决方案</p>
                    <h4>提供开源产品审查评估、本土化集成方案和运营服务</h4>
                </div>
                <div className="w3-content w3-section" style={{}}>
                    <div className="col-md-6">
                        <img
                            className="mySlides w3-animate-fading "
                            src="/thirdparty/static/Rocket.gif"
                            style={{ width: "100%" }}
                            id="fishing-img"
                        />
                    </div>
                </div>
            </div>
        </div>
        <section id="section2" className>
            <div id="section2" className="rellax" data-rellax-speed={0}>
                <div className="container">
                    <div className="col-sm-6 col-md-6">
                        <h1>解决方案供应商</h1>
                        <p style={{ textAlign: "center" }}>我们为您提供可靠的开源产品部署、管理方案和运营服务</p>
                        <p style={{ textAlign: "center" }}>让您拥有可靠的基础互联网服务</p>
                        <p />
                    </div>
                    <div className="col-sm-6 col-md-6 download">
                        <h1>了解更多...</h1>
                        <div style={{ textAlign: "center" }}> 
                            <button>
                                <a href="/">
                                    查看方案清单
                                </a>
                            </button>
                            <button>
                                <a href="mailto: mail@aftme.com">
                                    联系我们
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="footer">
            <div className="container">
                <div className="col-sm-3">
				    <GetYear></GetYear>
                    <br />
					<p>All rights reserved.</p>
                </div>
                <div className="col-sm-3"></div>
                <div className="col-sm-3"></div>
                <div class="col-sm-3">
                    <img src="/thirdparty/static/logo.svg" alt="蓝蝶-解决方案供应商"></img>
                    <p><a href="mailto: mail@aftme.com">mail@aftme.com</a></p>
                </div>
            </div>
        </div>
    </>;
}