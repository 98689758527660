import logo from './logo.svg';
import './App.css';
import React, { Component } from 'react';
import { HashRouter as Router, Link, Route } from 'react-router-dom';

import Index from "./html_component/index/index.js";

class App extends Component {
  render() {
    return (
      <Router>
        <Route path="/" exact component={Index.HtmlComponent}></Route>
      </Router>
    );
  }
}


export default App;
