// source: server.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var base_pb = require('./base_pb.js');
goog.object.extend(proto, base_pb);
goog.exportSymbol('proto.server.At', null, global);
goog.exportSymbol('proto.server.Auth', null, global);
goog.exportSymbol('proto.server.BalanceType', null, global);
goog.exportSymbol('proto.server.CollectTopic', null, global);
goog.exportSymbol('proto.server.CompanyAlive', null, global);
goog.exportSymbol('proto.server.CompanyInfo', null, global);
goog.exportSymbol('proto.server.DataType', null, global);
goog.exportSymbol('proto.server.Dns', null, global);
goog.exportSymbol('proto.server.MCurrency', null, global);
goog.exportSymbol('proto.server.MData', null, global);
goog.exportSymbol('proto.server.MNode', null, global);
goog.exportSymbol('proto.server.MOrder', null, global);
goog.exportSymbol('proto.server.MPaymentMethod', null, global);
goog.exportSymbol('proto.server.MPaymentPackage', null, global);
goog.exportSymbol('proto.server.MProduct', null, global);
goog.exportSymbol('proto.server.MProductListInfo', null, global);
goog.exportSymbol('proto.server.MProductPlanType', null, global);
goog.exportSymbol('proto.server.MProxyNode', null, global);
goog.exportSymbol('proto.server.MProxyPlan', null, global);
goog.exportSymbol('proto.server.MShop', null, global);
goog.exportSymbol('proto.server.MSystem', null, global);
goog.exportSymbol('proto.server.MTrafficPlan', null, global);
goog.exportSymbol('proto.server.MTranslate', null, global);
goog.exportSymbol('proto.server.MUpdate', null, global);
goog.exportSymbol('proto.server.MUser', null, global);
goog.exportSymbol('proto.server.MUserBalance', null, global);
goog.exportSymbol('proto.server.MUserBbs', null, global);
goog.exportSymbol('proto.server.MUserInfo', null, global);
goog.exportSymbol('proto.server.MUserPassword', null, global);
goog.exportSymbol('proto.server.MUserProxy', null, global);
goog.exportSymbol('proto.server.MUserSession', null, global);
goog.exportSymbol('proto.server.MUserTime', null, global);
goog.exportSymbol('proto.server.OrderState', null, global);
goog.exportSymbol('proto.server.PaymentMethod', null, global);
goog.exportSymbol('proto.server.PaymentMethodCode', null, global);
goog.exportSymbol('proto.server.PaymentPlan', null, global);
goog.exportSymbol('proto.server.PaymentState', null, global);
goog.exportSymbol('proto.server.ProxyNodeStatus', null, global);
goog.exportSymbol('proto.server.ProxyProviderIn', null, global);
goog.exportSymbol('proto.server.ProxyProviderOut', null, global);
goog.exportSymbol('proto.server.Reply', null, global);
goog.exportSymbol('proto.server.SalePlanProvider', null, global);
goog.exportSymbol('proto.server.ShopProviderIn', null, global);
goog.exportSymbol('proto.server.ShopProviderOut', null, global);
goog.exportSymbol('proto.server.Status', null, global);
goog.exportSymbol('proto.server.StripeInfo', null, global);
goog.exportSymbol('proto.server.UpdateProviderIn', null, global);
goog.exportSymbol('proto.server.UpdateProviderOut', null, global);
goog.exportSymbol('proto.server.UserAgent', null, global);
goog.exportSymbol('proto.server.UserPaymentCode', null, global);
goog.exportSymbol('proto.server.UserPaymentIn', null, global);
goog.exportSymbol('proto.server.UserPaymentOut', null, global);
goog.exportSymbol('proto.server.UserProviderIn', null, global);
goog.exportSymbol('proto.server.UserProviderOut', null, global);
goog.exportSymbol('proto.server.UserProxyConfig', null, global);
goog.exportSymbol('proto.server.UserProxyConfigProviderIn', null, global);
goog.exportSymbol('proto.server.UserProxyConfigProviderOut', null, global);
goog.exportSymbol('proto.server.UserType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.PaymentMethod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.PaymentMethod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.PaymentMethod.displayName = 'proto.server.PaymentMethod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.Auth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.Auth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.Auth.displayName = 'proto.server.Auth';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.UserAgent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.UserAgent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.UserAgent.displayName = 'proto.server.UserAgent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.UserProviderOut = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.UserProviderOut, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.UserProviderOut.displayName = 'proto.server.UserProviderOut';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.UserProviderIn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.UserProviderIn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.UserProviderIn.displayName = 'proto.server.UserProviderIn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.ShopProviderIn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.ShopProviderIn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.ShopProviderIn.displayName = 'proto.server.ShopProviderIn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.ShopProviderOut = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.ShopProviderOut, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.ShopProviderOut.displayName = 'proto.server.ShopProviderOut';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.ProxyProviderIn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.ProxyProviderIn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.ProxyProviderIn.displayName = 'proto.server.ProxyProviderIn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.ProxyProviderOut = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.ProxyProviderOut, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.ProxyProviderOut.displayName = 'proto.server.ProxyProviderOut';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.UpdateProviderIn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.UpdateProviderIn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.UpdateProviderIn.displayName = 'proto.server.UpdateProviderIn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.UpdateProviderOut = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.UpdateProviderOut, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.UpdateProviderOut.displayName = 'proto.server.UpdateProviderOut';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.UserPaymentIn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.UserPaymentIn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.UserPaymentIn.displayName = 'proto.server.UserPaymentIn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.StripeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.StripeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.StripeInfo.displayName = 'proto.server.StripeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.UserPaymentOut = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.UserPaymentOut, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.UserPaymentOut.displayName = 'proto.server.UserPaymentOut';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.UserProxyConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.UserProxyConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.UserProxyConfig.displayName = 'proto.server.UserProxyConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.UserProxyConfigProviderIn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.UserProxyConfigProviderIn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.UserProxyConfigProviderIn.displayName = 'proto.server.UserProxyConfigProviderIn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.UserProxyConfigProviderOut = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.UserProxyConfigProviderOut, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.UserProxyConfigProviderOut.displayName = 'proto.server.UserProxyConfigProviderOut';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.CompanyInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.CompanyInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.CompanyInfo.displayName = 'proto.server.CompanyInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.CompanyAlive = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.CompanyAlive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.CompanyAlive.displayName = 'proto.server.CompanyAlive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MUserPassword = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.MUserPassword, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MUserPassword.displayName = 'proto.server.MUserPassword';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MUserBalance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.MUserBalance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MUserBalance.displayName = 'proto.server.MUserBalance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MUserInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.MUserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MUserInfo.displayName = 'proto.server.MUserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MUserSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.MUserSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MUserSession.displayName = 'proto.server.MUserSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MUserBbs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.MUserBbs.repeatedFields_, null);
};
goog.inherits(proto.server.MUserBbs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MUserBbs.displayName = 'proto.server.MUserBbs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.CollectTopic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.CollectTopic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.CollectTopic.displayName = 'proto.server.CollectTopic';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.At = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.At, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.At.displayName = 'proto.server.At';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.Reply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.Reply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.Reply.displayName = 'proto.server.Reply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.MUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MUser.displayName = 'proto.server.MUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MUserTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.MUserTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MUserTime.displayName = 'proto.server.MUserTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.SalePlanProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.SalePlanProvider.repeatedFields_, null);
};
goog.inherits(proto.server.SalePlanProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.SalePlanProvider.displayName = 'proto.server.SalePlanProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.PaymentPlan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.PaymentPlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.PaymentPlan.displayName = 'proto.server.PaymentPlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MPaymentMethod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.MPaymentMethod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MPaymentMethod.displayName = 'proto.server.MPaymentMethod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MShop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.MShop.repeatedFields_, null);
};
goog.inherits(proto.server.MShop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MShop.displayName = 'proto.server.MShop';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MProductListInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.MProductListInfo.repeatedFields_, null);
};
goog.inherits(proto.server.MProductListInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MProductListInfo.displayName = 'proto.server.MProductListInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MCurrency = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.MCurrency, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MCurrency.displayName = 'proto.server.MCurrency';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MPaymentPackage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.MPaymentPackage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MPaymentPackage.displayName = 'proto.server.MPaymentPackage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MSystem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.MSystem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MSystem.displayName = 'proto.server.MSystem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.MUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MUpdate.displayName = 'proto.server.MUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MTranslate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.MTranslate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MTranslate.displayName = 'proto.server.MTranslate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.MOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MOrder.displayName = 'proto.server.MOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MProxyPlan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.MProxyPlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MProxyPlan.displayName = 'proto.server.MProxyPlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MTrafficPlan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.MTrafficPlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MTrafficPlan.displayName = 'proto.server.MTrafficPlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MUserProxy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.MUserProxy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MUserProxy.displayName = 'proto.server.MUserProxy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.MProduct.repeatedFields_, null);
};
goog.inherits(proto.server.MProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MProduct.displayName = 'proto.server.MProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MProxyNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.MProxyNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MProxyNode.displayName = 'proto.server.MProxyNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.MData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MData.displayName = 'proto.server.MData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.Dns = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.Dns.repeatedFields_, null);
};
goog.inherits(proto.server.Dns, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.Dns.displayName = 'proto.server.Dns';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.MNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.MNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.MNode.displayName = 'proto.server.MNode';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.PaymentMethod.prototype.toObject = function(opt_includeInstance) {
  return proto.server.PaymentMethod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.PaymentMethod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.PaymentMethod.toObject = function(includeInstance, msg) {
  var f, obj = {
    mpaymentmethod: (f = msg.getMpaymentmethod()) && proto.server.MPaymentMethod.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.PaymentMethod}
 */
proto.server.PaymentMethod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.PaymentMethod;
  return proto.server.PaymentMethod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.PaymentMethod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.PaymentMethod}
 */
proto.server.PaymentMethod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.server.MPaymentMethod;
      reader.readMessage(value,proto.server.MPaymentMethod.deserializeBinaryFromReader);
      msg.setMpaymentmethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.PaymentMethod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.PaymentMethod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.PaymentMethod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.PaymentMethod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMpaymentmethod();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.server.MPaymentMethod.serializeBinaryToWriter
    );
  }
};


/**
 * optional MPaymentMethod MPaymentMethod = 1;
 * @return {?proto.server.MPaymentMethod}
 */
proto.server.PaymentMethod.prototype.getMpaymentmethod = function() {
  return /** @type{?proto.server.MPaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto.server.MPaymentMethod, 1));
};


/**
 * @param {?proto.server.MPaymentMethod|undefined} value
 * @return {!proto.server.PaymentMethod} returns this
*/
proto.server.PaymentMethod.prototype.setMpaymentmethod = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.PaymentMethod} returns this
 */
proto.server.PaymentMethod.prototype.clearMpaymentmethod = function() {
  return this.setMpaymentmethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.PaymentMethod.prototype.hasMpaymentmethod = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.Auth.prototype.toObject = function(opt_includeInstance) {
  return proto.server.Auth.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.Auth} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.Auth.toObject = function(includeInstance, msg) {
  var f, obj = {
    authorization: (f = msg.getAuthorization()) && proto.server.UserAgent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.Auth}
 */
proto.server.Auth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.Auth;
  return proto.server.Auth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.Auth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.Auth}
 */
proto.server.Auth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.server.UserAgent;
      reader.readMessage(value,proto.server.UserAgent.deserializeBinaryFromReader);
      msg.setAuthorization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.Auth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.Auth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.Auth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.Auth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthorization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.server.UserAgent.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserAgent Authorization = 1;
 * @return {?proto.server.UserAgent}
 */
proto.server.Auth.prototype.getAuthorization = function() {
  return /** @type{?proto.server.UserAgent} */ (
    jspb.Message.getWrapperField(this, proto.server.UserAgent, 1));
};


/**
 * @param {?proto.server.UserAgent|undefined} value
 * @return {!proto.server.Auth} returns this
*/
proto.server.Auth.prototype.setAuthorization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.Auth} returns this
 */
proto.server.Auth.prototype.clearAuthorization = function() {
  return this.setAuthorization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.Auth.prototype.hasAuthorization = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.UserAgent.prototype.toObject = function(opt_includeInstance) {
  return proto.server.UserAgent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.UserAgent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UserAgent.toObject = function(includeInstance, msg) {
  var f, obj = {
    musersession: (f = msg.getMusersession()) && proto.server.MUserSession.toObject(includeInstance, f),
    usertype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.UserAgent}
 */
proto.server.UserAgent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.UserAgent;
  return proto.server.UserAgent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.UserAgent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.UserAgent}
 */
proto.server.UserAgent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.server.MUserSession;
      reader.readMessage(value,proto.server.MUserSession.deserializeBinaryFromReader);
      msg.setMusersession(value);
      break;
    case 2:
      var value = /** @type {!proto.server.UserType} */ (reader.readEnum());
      msg.setUsertype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.UserAgent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.UserAgent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.UserAgent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UserAgent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMusersession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.server.MUserSession.serializeBinaryToWriter
    );
  }
  f = message.getUsertype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional MUserSession MUserSession = 1;
 * @return {?proto.server.MUserSession}
 */
proto.server.UserAgent.prototype.getMusersession = function() {
  return /** @type{?proto.server.MUserSession} */ (
    jspb.Message.getWrapperField(this, proto.server.MUserSession, 1));
};


/**
 * @param {?proto.server.MUserSession|undefined} value
 * @return {!proto.server.UserAgent} returns this
*/
proto.server.UserAgent.prototype.setMusersession = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UserAgent} returns this
 */
proto.server.UserAgent.prototype.clearMusersession = function() {
  return this.setMusersession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UserAgent.prototype.hasMusersession = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserType UserType = 2;
 * @return {!proto.server.UserType}
 */
proto.server.UserAgent.prototype.getUsertype = function() {
  return /** @type {!proto.server.UserType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.server.UserType} value
 * @return {!proto.server.UserAgent} returns this
 */
proto.server.UserAgent.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.UserProviderOut.prototype.toObject = function(opt_includeInstance) {
  return proto.server.UserProviderOut.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.UserProviderOut} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UserProviderOut.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && base_pb.Base.toObject(includeInstance, f),
    muserinfo: (f = msg.getMuserinfo()) && proto.server.MUserInfo.toObject(includeInstance, f),
    musersession: (f = msg.getMusersession()) && proto.server.MUserSession.toObject(includeInstance, f),
    auth: (f = msg.getAuth()) && proto.server.Auth.toObject(includeInstance, f),
    muserbalance: (f = msg.getMuserbalance()) && proto.server.MUserBalance.toObject(includeInstance, f),
    muserproxy: (f = msg.getMuserproxy()) && proto.server.MUserProxy.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.UserProviderOut}
 */
proto.server.UserProviderOut.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.UserProviderOut;
  return proto.server.UserProviderOut.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.UserProviderOut} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.UserProviderOut}
 */
proto.server.UserProviderOut.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new base_pb.Base;
      reader.readMessage(value,base_pb.Base.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 2:
      var value = new proto.server.MUserInfo;
      reader.readMessage(value,proto.server.MUserInfo.deserializeBinaryFromReader);
      msg.setMuserinfo(value);
      break;
    case 3:
      var value = new proto.server.MUserSession;
      reader.readMessage(value,proto.server.MUserSession.deserializeBinaryFromReader);
      msg.setMusersession(value);
      break;
    case 4:
      var value = new proto.server.Auth;
      reader.readMessage(value,proto.server.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 6:
      var value = new proto.server.MUserBalance;
      reader.readMessage(value,proto.server.MUserBalance.deserializeBinaryFromReader);
      msg.setMuserbalance(value);
      break;
    case 7:
      var value = new proto.server.MUserProxy;
      reader.readMessage(value,proto.server.MUserProxy.deserializeBinaryFromReader);
      msg.setMuserproxy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.UserProviderOut.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.UserProviderOut.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.UserProviderOut} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UserProviderOut.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      base_pb.Base.serializeBinaryToWriter
    );
  }
  f = message.getMuserinfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.server.MUserInfo.serializeBinaryToWriter
    );
  }
  f = message.getMusersession();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.server.MUserSession.serializeBinaryToWriter
    );
  }
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.server.Auth.serializeBinaryToWriter
    );
  }
  f = message.getMuserbalance();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.server.MUserBalance.serializeBinaryToWriter
    );
  }
  f = message.getMuserproxy();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.server.MUserProxy.serializeBinaryToWriter
    );
  }
};


/**
 * optional base.Base Base = 1;
 * @return {?proto.base.Base}
 */
proto.server.UserProviderOut.prototype.getBase = function() {
  return /** @type{?proto.base.Base} */ (
    jspb.Message.getWrapperField(this, base_pb.Base, 1));
};


/**
 * @param {?proto.base.Base|undefined} value
 * @return {!proto.server.UserProviderOut} returns this
*/
proto.server.UserProviderOut.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UserProviderOut} returns this
 */
proto.server.UserProviderOut.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UserProviderOut.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MUserInfo MUserInfo = 2;
 * @return {?proto.server.MUserInfo}
 */
proto.server.UserProviderOut.prototype.getMuserinfo = function() {
  return /** @type{?proto.server.MUserInfo} */ (
    jspb.Message.getWrapperField(this, proto.server.MUserInfo, 2));
};


/**
 * @param {?proto.server.MUserInfo|undefined} value
 * @return {!proto.server.UserProviderOut} returns this
*/
proto.server.UserProviderOut.prototype.setMuserinfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UserProviderOut} returns this
 */
proto.server.UserProviderOut.prototype.clearMuserinfo = function() {
  return this.setMuserinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UserProviderOut.prototype.hasMuserinfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MUserSession MUserSession = 3;
 * @return {?proto.server.MUserSession}
 */
proto.server.UserProviderOut.prototype.getMusersession = function() {
  return /** @type{?proto.server.MUserSession} */ (
    jspb.Message.getWrapperField(this, proto.server.MUserSession, 3));
};


/**
 * @param {?proto.server.MUserSession|undefined} value
 * @return {!proto.server.UserProviderOut} returns this
*/
proto.server.UserProviderOut.prototype.setMusersession = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UserProviderOut} returns this
 */
proto.server.UserProviderOut.prototype.clearMusersession = function() {
  return this.setMusersession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UserProviderOut.prototype.hasMusersession = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Auth Auth = 4;
 * @return {?proto.server.Auth}
 */
proto.server.UserProviderOut.prototype.getAuth = function() {
  return /** @type{?proto.server.Auth} */ (
    jspb.Message.getWrapperField(this, proto.server.Auth, 4));
};


/**
 * @param {?proto.server.Auth|undefined} value
 * @return {!proto.server.UserProviderOut} returns this
*/
proto.server.UserProviderOut.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UserProviderOut} returns this
 */
proto.server.UserProviderOut.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UserProviderOut.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional MUserBalance MUserBalance = 6;
 * @return {?proto.server.MUserBalance}
 */
proto.server.UserProviderOut.prototype.getMuserbalance = function() {
  return /** @type{?proto.server.MUserBalance} */ (
    jspb.Message.getWrapperField(this, proto.server.MUserBalance, 6));
};


/**
 * @param {?proto.server.MUserBalance|undefined} value
 * @return {!proto.server.UserProviderOut} returns this
*/
proto.server.UserProviderOut.prototype.setMuserbalance = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UserProviderOut} returns this
 */
proto.server.UserProviderOut.prototype.clearMuserbalance = function() {
  return this.setMuserbalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UserProviderOut.prototype.hasMuserbalance = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional MUserProxy MUserProxy = 7;
 * @return {?proto.server.MUserProxy}
 */
proto.server.UserProviderOut.prototype.getMuserproxy = function() {
  return /** @type{?proto.server.MUserProxy} */ (
    jspb.Message.getWrapperField(this, proto.server.MUserProxy, 7));
};


/**
 * @param {?proto.server.MUserProxy|undefined} value
 * @return {!proto.server.UserProviderOut} returns this
*/
proto.server.UserProviderOut.prototype.setMuserproxy = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UserProviderOut} returns this
 */
proto.server.UserProviderOut.prototype.clearMuserproxy = function() {
  return this.setMuserproxy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UserProviderOut.prototype.hasMuserproxy = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.UserProviderIn.prototype.toObject = function(opt_includeInstance) {
  return proto.server.UserProviderIn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.UserProviderIn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UserProviderIn.toObject = function(includeInstance, msg) {
  var f, obj = {
    muserinfo: (f = msg.getMuserinfo()) && proto.server.MUserInfo.toObject(includeInstance, f),
    muserpassword: (f = msg.getMuserpassword()) && proto.server.MUserPassword.toObject(includeInstance, f),
    auth: (f = msg.getAuth()) && proto.server.Auth.toObject(includeInstance, f),
    musernewpassword: (f = msg.getMusernewpassword()) && proto.server.MUserPassword.toObject(includeInstance, f),
    muserproxy: (f = msg.getMuserproxy()) && proto.server.MUserProxy.toObject(includeInstance, f),
    hostname: jspb.Message.getFieldWithDefault(msg, 7, ""),
    ipaddr: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.UserProviderIn}
 */
proto.server.UserProviderIn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.UserProviderIn;
  return proto.server.UserProviderIn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.UserProviderIn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.UserProviderIn}
 */
proto.server.UserProviderIn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.server.MUserInfo;
      reader.readMessage(value,proto.server.MUserInfo.deserializeBinaryFromReader);
      msg.setMuserinfo(value);
      break;
    case 3:
      var value = new proto.server.MUserPassword;
      reader.readMessage(value,proto.server.MUserPassword.deserializeBinaryFromReader);
      msg.setMuserpassword(value);
      break;
    case 4:
      var value = new proto.server.Auth;
      reader.readMessage(value,proto.server.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 5:
      var value = new proto.server.MUserPassword;
      reader.readMessage(value,proto.server.MUserPassword.deserializeBinaryFromReader);
      msg.setMusernewpassword(value);
      break;
    case 6:
      var value = new proto.server.MUserProxy;
      reader.readMessage(value,proto.server.MUserProxy.deserializeBinaryFromReader);
      msg.setMuserproxy(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostname(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpaddr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.UserProviderIn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.UserProviderIn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.UserProviderIn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UserProviderIn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMuserinfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.server.MUserInfo.serializeBinaryToWriter
    );
  }
  f = message.getMuserpassword();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.server.MUserPassword.serializeBinaryToWriter
    );
  }
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.server.Auth.serializeBinaryToWriter
    );
  }
  f = message.getMusernewpassword();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.server.MUserPassword.serializeBinaryToWriter
    );
  }
  f = message.getMuserproxy();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.server.MUserProxy.serializeBinaryToWriter
    );
  }
  f = message.getHostname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIpaddr();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional MUserInfo MUserInfo = 2;
 * @return {?proto.server.MUserInfo}
 */
proto.server.UserProviderIn.prototype.getMuserinfo = function() {
  return /** @type{?proto.server.MUserInfo} */ (
    jspb.Message.getWrapperField(this, proto.server.MUserInfo, 2));
};


/**
 * @param {?proto.server.MUserInfo|undefined} value
 * @return {!proto.server.UserProviderIn} returns this
*/
proto.server.UserProviderIn.prototype.setMuserinfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UserProviderIn} returns this
 */
proto.server.UserProviderIn.prototype.clearMuserinfo = function() {
  return this.setMuserinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UserProviderIn.prototype.hasMuserinfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MUserPassword MUserPassword = 3;
 * @return {?proto.server.MUserPassword}
 */
proto.server.UserProviderIn.prototype.getMuserpassword = function() {
  return /** @type{?proto.server.MUserPassword} */ (
    jspb.Message.getWrapperField(this, proto.server.MUserPassword, 3));
};


/**
 * @param {?proto.server.MUserPassword|undefined} value
 * @return {!proto.server.UserProviderIn} returns this
*/
proto.server.UserProviderIn.prototype.setMuserpassword = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UserProviderIn} returns this
 */
proto.server.UserProviderIn.prototype.clearMuserpassword = function() {
  return this.setMuserpassword(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UserProviderIn.prototype.hasMuserpassword = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Auth Auth = 4;
 * @return {?proto.server.Auth}
 */
proto.server.UserProviderIn.prototype.getAuth = function() {
  return /** @type{?proto.server.Auth} */ (
    jspb.Message.getWrapperField(this, proto.server.Auth, 4));
};


/**
 * @param {?proto.server.Auth|undefined} value
 * @return {!proto.server.UserProviderIn} returns this
*/
proto.server.UserProviderIn.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UserProviderIn} returns this
 */
proto.server.UserProviderIn.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UserProviderIn.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional MUserPassword MUserNewPassword = 5;
 * @return {?proto.server.MUserPassword}
 */
proto.server.UserProviderIn.prototype.getMusernewpassword = function() {
  return /** @type{?proto.server.MUserPassword} */ (
    jspb.Message.getWrapperField(this, proto.server.MUserPassword, 5));
};


/**
 * @param {?proto.server.MUserPassword|undefined} value
 * @return {!proto.server.UserProviderIn} returns this
*/
proto.server.UserProviderIn.prototype.setMusernewpassword = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UserProviderIn} returns this
 */
proto.server.UserProviderIn.prototype.clearMusernewpassword = function() {
  return this.setMusernewpassword(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UserProviderIn.prototype.hasMusernewpassword = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional MUserProxy MUserProxy = 6;
 * @return {?proto.server.MUserProxy}
 */
proto.server.UserProviderIn.prototype.getMuserproxy = function() {
  return /** @type{?proto.server.MUserProxy} */ (
    jspb.Message.getWrapperField(this, proto.server.MUserProxy, 6));
};


/**
 * @param {?proto.server.MUserProxy|undefined} value
 * @return {!proto.server.UserProviderIn} returns this
*/
proto.server.UserProviderIn.prototype.setMuserproxy = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UserProviderIn} returns this
 */
proto.server.UserProviderIn.prototype.clearMuserproxy = function() {
  return this.setMuserproxy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UserProviderIn.prototype.hasMuserproxy = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string HostName = 7;
 * @return {string}
 */
proto.server.UserProviderIn.prototype.getHostname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.UserProviderIn} returns this
 */
proto.server.UserProviderIn.prototype.setHostname = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string IpAddr = 8;
 * @return {string}
 */
proto.server.UserProviderIn.prototype.getIpaddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.UserProviderIn} returns this
 */
proto.server.UserProviderIn.prototype.setIpaddr = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.ShopProviderIn.prototype.toObject = function(opt_includeInstance) {
  return proto.server.ShopProviderIn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.ShopProviderIn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ShopProviderIn.toObject = function(includeInstance, msg) {
  var f, obj = {
    mshop: (f = msg.getMshop()) && proto.server.MShop.toObject(includeInstance, f),
    mproduct: (f = msg.getMproduct()) && proto.server.MProduct.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.ShopProviderIn}
 */
proto.server.ShopProviderIn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.ShopProviderIn;
  return proto.server.ShopProviderIn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.ShopProviderIn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.ShopProviderIn}
 */
proto.server.ShopProviderIn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.server.MShop;
      reader.readMessage(value,proto.server.MShop.deserializeBinaryFromReader);
      msg.setMshop(value);
      break;
    case 2:
      var value = new proto.server.MProduct;
      reader.readMessage(value,proto.server.MProduct.deserializeBinaryFromReader);
      msg.setMproduct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.ShopProviderIn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.ShopProviderIn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.ShopProviderIn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ShopProviderIn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMshop();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.server.MShop.serializeBinaryToWriter
    );
  }
  f = message.getMproduct();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.server.MProduct.serializeBinaryToWriter
    );
  }
};


/**
 * optional MShop MShop = 1;
 * @return {?proto.server.MShop}
 */
proto.server.ShopProviderIn.prototype.getMshop = function() {
  return /** @type{?proto.server.MShop} */ (
    jspb.Message.getWrapperField(this, proto.server.MShop, 1));
};


/**
 * @param {?proto.server.MShop|undefined} value
 * @return {!proto.server.ShopProviderIn} returns this
*/
proto.server.ShopProviderIn.prototype.setMshop = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.ShopProviderIn} returns this
 */
proto.server.ShopProviderIn.prototype.clearMshop = function() {
  return this.setMshop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.ShopProviderIn.prototype.hasMshop = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MProduct MProduct = 2;
 * @return {?proto.server.MProduct}
 */
proto.server.ShopProviderIn.prototype.getMproduct = function() {
  return /** @type{?proto.server.MProduct} */ (
    jspb.Message.getWrapperField(this, proto.server.MProduct, 2));
};


/**
 * @param {?proto.server.MProduct|undefined} value
 * @return {!proto.server.ShopProviderIn} returns this
*/
proto.server.ShopProviderIn.prototype.setMproduct = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.ShopProviderIn} returns this
 */
proto.server.ShopProviderIn.prototype.clearMproduct = function() {
  return this.setMproduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.ShopProviderIn.prototype.hasMproduct = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.ShopProviderOut.prototype.toObject = function(opt_includeInstance) {
  return proto.server.ShopProviderOut.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.ShopProviderOut} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ShopProviderOut.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && base_pb.Base.toObject(includeInstance, f),
    mshop: (f = msg.getMshop()) && proto.server.MShop.toObject(includeInstance, f),
    mproduct: (f = msg.getMproduct()) && proto.server.MProduct.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.ShopProviderOut}
 */
proto.server.ShopProviderOut.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.ShopProviderOut;
  return proto.server.ShopProviderOut.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.ShopProviderOut} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.ShopProviderOut}
 */
proto.server.ShopProviderOut.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new base_pb.Base;
      reader.readMessage(value,base_pb.Base.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 2:
      var value = new proto.server.MShop;
      reader.readMessage(value,proto.server.MShop.deserializeBinaryFromReader);
      msg.setMshop(value);
      break;
    case 4:
      var value = new proto.server.MProduct;
      reader.readMessage(value,proto.server.MProduct.deserializeBinaryFromReader);
      msg.setMproduct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.ShopProviderOut.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.ShopProviderOut.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.ShopProviderOut} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ShopProviderOut.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      base_pb.Base.serializeBinaryToWriter
    );
  }
  f = message.getMshop();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.server.MShop.serializeBinaryToWriter
    );
  }
  f = message.getMproduct();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.server.MProduct.serializeBinaryToWriter
    );
  }
};


/**
 * optional base.Base Base = 1;
 * @return {?proto.base.Base}
 */
proto.server.ShopProviderOut.prototype.getBase = function() {
  return /** @type{?proto.base.Base} */ (
    jspb.Message.getWrapperField(this, base_pb.Base, 1));
};


/**
 * @param {?proto.base.Base|undefined} value
 * @return {!proto.server.ShopProviderOut} returns this
*/
proto.server.ShopProviderOut.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.ShopProviderOut} returns this
 */
proto.server.ShopProviderOut.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.ShopProviderOut.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MShop MShop = 2;
 * @return {?proto.server.MShop}
 */
proto.server.ShopProviderOut.prototype.getMshop = function() {
  return /** @type{?proto.server.MShop} */ (
    jspb.Message.getWrapperField(this, proto.server.MShop, 2));
};


/**
 * @param {?proto.server.MShop|undefined} value
 * @return {!proto.server.ShopProviderOut} returns this
*/
proto.server.ShopProviderOut.prototype.setMshop = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.ShopProviderOut} returns this
 */
proto.server.ShopProviderOut.prototype.clearMshop = function() {
  return this.setMshop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.ShopProviderOut.prototype.hasMshop = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MProduct MProduct = 4;
 * @return {?proto.server.MProduct}
 */
proto.server.ShopProviderOut.prototype.getMproduct = function() {
  return /** @type{?proto.server.MProduct} */ (
    jspb.Message.getWrapperField(this, proto.server.MProduct, 4));
};


/**
 * @param {?proto.server.MProduct|undefined} value
 * @return {!proto.server.ShopProviderOut} returns this
*/
proto.server.ShopProviderOut.prototype.setMproduct = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.ShopProviderOut} returns this
 */
proto.server.ShopProviderOut.prototype.clearMproduct = function() {
  return this.setMproduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.ShopProviderOut.prototype.hasMproduct = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.ProxyProviderIn.prototype.toObject = function(opt_includeInstance) {
  return proto.server.ProxyProviderIn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.ProxyProviderIn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ProxyProviderIn.toObject = function(includeInstance, msg) {
  var f, obj = {
    ipaddr: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.ProxyProviderIn}
 */
proto.server.ProxyProviderIn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.ProxyProviderIn;
  return proto.server.ProxyProviderIn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.ProxyProviderIn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.ProxyProviderIn}
 */
proto.server.ProxyProviderIn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpaddr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.ProxyProviderIn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.ProxyProviderIn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.ProxyProviderIn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ProxyProviderIn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIpaddr();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string IpAddr = 1;
 * @return {string}
 */
proto.server.ProxyProviderIn.prototype.getIpaddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ProxyProviderIn} returns this
 */
proto.server.ProxyProviderIn.prototype.setIpaddr = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.ProxyProviderOut.prototype.toObject = function(opt_includeInstance) {
  return proto.server.ProxyProviderOut.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.ProxyProviderOut} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ProxyProviderOut.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && base_pb.Base.toObject(includeInstance, f),
    mproxynode: (f = msg.getMproxynode()) && proto.server.MProxyNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.ProxyProviderOut}
 */
proto.server.ProxyProviderOut.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.ProxyProviderOut;
  return proto.server.ProxyProviderOut.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.ProxyProviderOut} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.ProxyProviderOut}
 */
proto.server.ProxyProviderOut.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new base_pb.Base;
      reader.readMessage(value,base_pb.Base.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 2:
      var value = new proto.server.MProxyNode;
      reader.readMessage(value,proto.server.MProxyNode.deserializeBinaryFromReader);
      msg.setMproxynode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.ProxyProviderOut.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.ProxyProviderOut.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.ProxyProviderOut} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ProxyProviderOut.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      base_pb.Base.serializeBinaryToWriter
    );
  }
  f = message.getMproxynode();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.server.MProxyNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional base.Base Base = 1;
 * @return {?proto.base.Base}
 */
proto.server.ProxyProviderOut.prototype.getBase = function() {
  return /** @type{?proto.base.Base} */ (
    jspb.Message.getWrapperField(this, base_pb.Base, 1));
};


/**
 * @param {?proto.base.Base|undefined} value
 * @return {!proto.server.ProxyProviderOut} returns this
*/
proto.server.ProxyProviderOut.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.ProxyProviderOut} returns this
 */
proto.server.ProxyProviderOut.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.ProxyProviderOut.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MProxyNode MProxyNode = 2;
 * @return {?proto.server.MProxyNode}
 */
proto.server.ProxyProviderOut.prototype.getMproxynode = function() {
  return /** @type{?proto.server.MProxyNode} */ (
    jspb.Message.getWrapperField(this, proto.server.MProxyNode, 2));
};


/**
 * @param {?proto.server.MProxyNode|undefined} value
 * @return {!proto.server.ProxyProviderOut} returns this
*/
proto.server.ProxyProviderOut.prototype.setMproxynode = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.ProxyProviderOut} returns this
 */
proto.server.ProxyProviderOut.prototype.clearMproxynode = function() {
  return this.setMproxynode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.ProxyProviderOut.prototype.hasMproxynode = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.UpdateProviderIn.prototype.toObject = function(opt_includeInstance) {
  return proto.server.UpdateProviderIn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.UpdateProviderIn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UpdateProviderIn.toObject = function(includeInstance, msg) {
  var f, obj = {
    mupdate: (f = msg.getMupdate()) && proto.server.MUpdate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.UpdateProviderIn}
 */
proto.server.UpdateProviderIn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.UpdateProviderIn;
  return proto.server.UpdateProviderIn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.UpdateProviderIn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.UpdateProviderIn}
 */
proto.server.UpdateProviderIn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.server.MUpdate;
      reader.readMessage(value,proto.server.MUpdate.deserializeBinaryFromReader);
      msg.setMupdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.UpdateProviderIn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.UpdateProviderIn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.UpdateProviderIn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UpdateProviderIn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMupdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.server.MUpdate.serializeBinaryToWriter
    );
  }
};


/**
 * optional MUpdate MUpdate = 1;
 * @return {?proto.server.MUpdate}
 */
proto.server.UpdateProviderIn.prototype.getMupdate = function() {
  return /** @type{?proto.server.MUpdate} */ (
    jspb.Message.getWrapperField(this, proto.server.MUpdate, 1));
};


/**
 * @param {?proto.server.MUpdate|undefined} value
 * @return {!proto.server.UpdateProviderIn} returns this
*/
proto.server.UpdateProviderIn.prototype.setMupdate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UpdateProviderIn} returns this
 */
proto.server.UpdateProviderIn.prototype.clearMupdate = function() {
  return this.setMupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UpdateProviderIn.prototype.hasMupdate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.UpdateProviderOut.prototype.toObject = function(opt_includeInstance) {
  return proto.server.UpdateProviderOut.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.UpdateProviderOut} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UpdateProviderOut.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && base_pb.Base.toObject(includeInstance, f),
    mupdate: (f = msg.getMupdate()) && proto.server.MUpdate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.UpdateProviderOut}
 */
proto.server.UpdateProviderOut.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.UpdateProviderOut;
  return proto.server.UpdateProviderOut.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.UpdateProviderOut} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.UpdateProviderOut}
 */
proto.server.UpdateProviderOut.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new base_pb.Base;
      reader.readMessage(value,base_pb.Base.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 2:
      var value = new proto.server.MUpdate;
      reader.readMessage(value,proto.server.MUpdate.deserializeBinaryFromReader);
      msg.setMupdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.UpdateProviderOut.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.UpdateProviderOut.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.UpdateProviderOut} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UpdateProviderOut.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      base_pb.Base.serializeBinaryToWriter
    );
  }
  f = message.getMupdate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.server.MUpdate.serializeBinaryToWriter
    );
  }
};


/**
 * optional base.Base Base = 1;
 * @return {?proto.base.Base}
 */
proto.server.UpdateProviderOut.prototype.getBase = function() {
  return /** @type{?proto.base.Base} */ (
    jspb.Message.getWrapperField(this, base_pb.Base, 1));
};


/**
 * @param {?proto.base.Base|undefined} value
 * @return {!proto.server.UpdateProviderOut} returns this
*/
proto.server.UpdateProviderOut.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UpdateProviderOut} returns this
 */
proto.server.UpdateProviderOut.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UpdateProviderOut.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MUpdate MUpdate = 2;
 * @return {?proto.server.MUpdate}
 */
proto.server.UpdateProviderOut.prototype.getMupdate = function() {
  return /** @type{?proto.server.MUpdate} */ (
    jspb.Message.getWrapperField(this, proto.server.MUpdate, 2));
};


/**
 * @param {?proto.server.MUpdate|undefined} value
 * @return {!proto.server.UpdateProviderOut} returns this
*/
proto.server.UpdateProviderOut.prototype.setMupdate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UpdateProviderOut} returns this
 */
proto.server.UpdateProviderOut.prototype.clearMupdate = function() {
  return this.setMupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UpdateProviderOut.prototype.hasMupdate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.UserPaymentIn.prototype.toObject = function(opt_includeInstance) {
  return proto.server.UserPaymentIn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.UserPaymentIn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UserPaymentIn.toObject = function(includeInstance, msg) {
  var f, obj = {
    mshop: (f = msg.getMshop()) && proto.server.MShop.toObject(includeInstance, f),
    mproduct: (f = msg.getMproduct()) && proto.server.MProduct.toObject(includeInstance, f),
    mpaymentpackage: (f = msg.getMpaymentpackage()) && proto.server.MPaymentPackage.toObject(includeInstance, f),
    mpaymentmethod: (f = msg.getMpaymentmethod()) && proto.server.MPaymentMethod.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.UserPaymentIn}
 */
proto.server.UserPaymentIn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.UserPaymentIn;
  return proto.server.UserPaymentIn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.UserPaymentIn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.UserPaymentIn}
 */
proto.server.UserPaymentIn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.server.MShop;
      reader.readMessage(value,proto.server.MShop.deserializeBinaryFromReader);
      msg.setMshop(value);
      break;
    case 2:
      var value = new proto.server.MProduct;
      reader.readMessage(value,proto.server.MProduct.deserializeBinaryFromReader);
      msg.setMproduct(value);
      break;
    case 3:
      var value = new proto.server.MPaymentPackage;
      reader.readMessage(value,proto.server.MPaymentPackage.deserializeBinaryFromReader);
      msg.setMpaymentpackage(value);
      break;
    case 4:
      var value = new proto.server.MPaymentMethod;
      reader.readMessage(value,proto.server.MPaymentMethod.deserializeBinaryFromReader);
      msg.setMpaymentmethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.UserPaymentIn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.UserPaymentIn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.UserPaymentIn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UserPaymentIn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMshop();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.server.MShop.serializeBinaryToWriter
    );
  }
  f = message.getMproduct();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.server.MProduct.serializeBinaryToWriter
    );
  }
  f = message.getMpaymentpackage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.server.MPaymentPackage.serializeBinaryToWriter
    );
  }
  f = message.getMpaymentmethod();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.server.MPaymentMethod.serializeBinaryToWriter
    );
  }
};


/**
 * optional MShop MShop = 1;
 * @return {?proto.server.MShop}
 */
proto.server.UserPaymentIn.prototype.getMshop = function() {
  return /** @type{?proto.server.MShop} */ (
    jspb.Message.getWrapperField(this, proto.server.MShop, 1));
};


/**
 * @param {?proto.server.MShop|undefined} value
 * @return {!proto.server.UserPaymentIn} returns this
*/
proto.server.UserPaymentIn.prototype.setMshop = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UserPaymentIn} returns this
 */
proto.server.UserPaymentIn.prototype.clearMshop = function() {
  return this.setMshop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UserPaymentIn.prototype.hasMshop = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MProduct MProduct = 2;
 * @return {?proto.server.MProduct}
 */
proto.server.UserPaymentIn.prototype.getMproduct = function() {
  return /** @type{?proto.server.MProduct} */ (
    jspb.Message.getWrapperField(this, proto.server.MProduct, 2));
};


/**
 * @param {?proto.server.MProduct|undefined} value
 * @return {!proto.server.UserPaymentIn} returns this
*/
proto.server.UserPaymentIn.prototype.setMproduct = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UserPaymentIn} returns this
 */
proto.server.UserPaymentIn.prototype.clearMproduct = function() {
  return this.setMproduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UserPaymentIn.prototype.hasMproduct = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MPaymentPackage MPaymentPackage = 3;
 * @return {?proto.server.MPaymentPackage}
 */
proto.server.UserPaymentIn.prototype.getMpaymentpackage = function() {
  return /** @type{?proto.server.MPaymentPackage} */ (
    jspb.Message.getWrapperField(this, proto.server.MPaymentPackage, 3));
};


/**
 * @param {?proto.server.MPaymentPackage|undefined} value
 * @return {!proto.server.UserPaymentIn} returns this
*/
proto.server.UserPaymentIn.prototype.setMpaymentpackage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UserPaymentIn} returns this
 */
proto.server.UserPaymentIn.prototype.clearMpaymentpackage = function() {
  return this.setMpaymentpackage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UserPaymentIn.prototype.hasMpaymentpackage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional MPaymentMethod MPaymentMethod = 4;
 * @return {?proto.server.MPaymentMethod}
 */
proto.server.UserPaymentIn.prototype.getMpaymentmethod = function() {
  return /** @type{?proto.server.MPaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto.server.MPaymentMethod, 4));
};


/**
 * @param {?proto.server.MPaymentMethod|undefined} value
 * @return {!proto.server.UserPaymentIn} returns this
*/
proto.server.UserPaymentIn.prototype.setMpaymentmethod = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UserPaymentIn} returns this
 */
proto.server.UserPaymentIn.prototype.clearMpaymentmethod = function() {
  return this.setMpaymentmethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UserPaymentIn.prototype.hasMpaymentmethod = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.StripeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.server.StripeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.StripeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.StripeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    apikey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    paymentintentid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.StripeInfo}
 */
proto.server.StripeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.StripeInfo;
  return proto.server.StripeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.StripeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.StripeInfo}
 */
proto.server.StripeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApikey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentintentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.StripeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.StripeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.StripeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.StripeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApikey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPaymentintentid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string SessionId = 1;
 * @return {string}
 */
proto.server.StripeInfo.prototype.getSessionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.StripeInfo} returns this
 */
proto.server.StripeInfo.prototype.setSessionid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ApiKey = 2;
 * @return {string}
 */
proto.server.StripeInfo.prototype.getApikey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.StripeInfo} returns this
 */
proto.server.StripeInfo.prototype.setApikey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string PaymentIntentId = 3;
 * @return {string}
 */
proto.server.StripeInfo.prototype.getPaymentintentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.StripeInfo} returns this
 */
proto.server.StripeInfo.prototype.setPaymentintentid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.UserPaymentOut.prototype.toObject = function(opt_includeInstance) {
  return proto.server.UserPaymentOut.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.UserPaymentOut} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UserPaymentOut.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && base_pb.Base.toObject(includeInstance, f),
    userpaymentcode: jspb.Message.getFieldWithDefault(msg, 2, 0),
    stripeinfo: (f = msg.getStripeinfo()) && proto.server.StripeInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.UserPaymentOut}
 */
proto.server.UserPaymentOut.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.UserPaymentOut;
  return proto.server.UserPaymentOut.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.UserPaymentOut} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.UserPaymentOut}
 */
proto.server.UserPaymentOut.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new base_pb.Base;
      reader.readMessage(value,base_pb.Base.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 2:
      var value = /** @type {!proto.server.UserPaymentCode} */ (reader.readEnum());
      msg.setUserpaymentcode(value);
      break;
    case 4:
      var value = new proto.server.StripeInfo;
      reader.readMessage(value,proto.server.StripeInfo.deserializeBinaryFromReader);
      msg.setStripeinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.UserPaymentOut.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.UserPaymentOut.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.UserPaymentOut} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UserPaymentOut.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      base_pb.Base.serializeBinaryToWriter
    );
  }
  f = message.getUserpaymentcode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStripeinfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.server.StripeInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional base.Base Base = 1;
 * @return {?proto.base.Base}
 */
proto.server.UserPaymentOut.prototype.getBase = function() {
  return /** @type{?proto.base.Base} */ (
    jspb.Message.getWrapperField(this, base_pb.Base, 1));
};


/**
 * @param {?proto.base.Base|undefined} value
 * @return {!proto.server.UserPaymentOut} returns this
*/
proto.server.UserPaymentOut.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UserPaymentOut} returns this
 */
proto.server.UserPaymentOut.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UserPaymentOut.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserPaymentCode UserPaymentCode = 2;
 * @return {!proto.server.UserPaymentCode}
 */
proto.server.UserPaymentOut.prototype.getUserpaymentcode = function() {
  return /** @type {!proto.server.UserPaymentCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.server.UserPaymentCode} value
 * @return {!proto.server.UserPaymentOut} returns this
 */
proto.server.UserPaymentOut.prototype.setUserpaymentcode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional StripeInfo StripeInfo = 4;
 * @return {?proto.server.StripeInfo}
 */
proto.server.UserPaymentOut.prototype.getStripeinfo = function() {
  return /** @type{?proto.server.StripeInfo} */ (
    jspb.Message.getWrapperField(this, proto.server.StripeInfo, 4));
};


/**
 * @param {?proto.server.StripeInfo|undefined} value
 * @return {!proto.server.UserPaymentOut} returns this
*/
proto.server.UserPaymentOut.prototype.setStripeinfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UserPaymentOut} returns this
 */
proto.server.UserPaymentOut.prototype.clearStripeinfo = function() {
  return this.setStripeinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UserPaymentOut.prototype.hasStripeinfo = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.UserProxyConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.server.UserProxyConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.UserProxyConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UserProxyConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    content: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.UserProxyConfig}
 */
proto.server.UserProxyConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.UserProxyConfig;
  return proto.server.UserProxyConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.UserProxyConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.UserProxyConfig}
 */
proto.server.UserProxyConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.UserProxyConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.UserProxyConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.UserProxyConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UserProxyConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Content = 1;
 * @return {string}
 */
proto.server.UserProxyConfig.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.UserProxyConfig} returns this
 */
proto.server.UserProxyConfig.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.UserProxyConfigProviderIn.prototype.toObject = function(opt_includeInstance) {
  return proto.server.UserProxyConfigProviderIn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.UserProxyConfigProviderIn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UserProxyConfigProviderIn.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.UserProxyConfigProviderIn}
 */
proto.server.UserProxyConfigProviderIn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.UserProxyConfigProviderIn;
  return proto.server.UserProxyConfigProviderIn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.UserProxyConfigProviderIn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.UserProxyConfigProviderIn}
 */
proto.server.UserProxyConfigProviderIn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.UserProxyConfigProviderIn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.UserProxyConfigProviderIn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.UserProxyConfigProviderIn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UserProxyConfigProviderIn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.UserProxyConfigProviderOut.prototype.toObject = function(opt_includeInstance) {
  return proto.server.UserProxyConfigProviderOut.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.UserProxyConfigProviderOut} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UserProxyConfigProviderOut.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && base_pb.Base.toObject(includeInstance, f),
    userproxyconfig: (f = msg.getUserproxyconfig()) && proto.server.UserProxyConfig.toObject(includeInstance, f),
    mtranslateproxyMap: (f = msg.getMtranslateproxyMap()) ? f.toObject(includeInstance, proto.server.MTranslate.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.UserProxyConfigProviderOut}
 */
proto.server.UserProxyConfigProviderOut.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.UserProxyConfigProviderOut;
  return proto.server.UserProxyConfigProviderOut.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.UserProxyConfigProviderOut} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.UserProxyConfigProviderOut}
 */
proto.server.UserProxyConfigProviderOut.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new base_pb.Base;
      reader.readMessage(value,base_pb.Base.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 2:
      var value = new proto.server.UserProxyConfig;
      reader.readMessage(value,proto.server.UserProxyConfig.deserializeBinaryFromReader);
      msg.setUserproxyconfig(value);
      break;
    case 3:
      var value = msg.getMtranslateproxyMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.server.MTranslate.deserializeBinaryFromReader, "", new proto.server.MTranslate());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.UserProxyConfigProviderOut.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.UserProxyConfigProviderOut.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.UserProxyConfigProviderOut} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UserProxyConfigProviderOut.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      base_pb.Base.serializeBinaryToWriter
    );
  }
  f = message.getUserproxyconfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.server.UserProxyConfig.serializeBinaryToWriter
    );
  }
  f = message.getMtranslateproxyMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.server.MTranslate.serializeBinaryToWriter);
  }
};


/**
 * optional base.Base Base = 1;
 * @return {?proto.base.Base}
 */
proto.server.UserProxyConfigProviderOut.prototype.getBase = function() {
  return /** @type{?proto.base.Base} */ (
    jspb.Message.getWrapperField(this, base_pb.Base, 1));
};


/**
 * @param {?proto.base.Base|undefined} value
 * @return {!proto.server.UserProxyConfigProviderOut} returns this
*/
proto.server.UserProxyConfigProviderOut.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UserProxyConfigProviderOut} returns this
 */
proto.server.UserProxyConfigProviderOut.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UserProxyConfigProviderOut.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserProxyConfig UserProxyConfig = 2;
 * @return {?proto.server.UserProxyConfig}
 */
proto.server.UserProxyConfigProviderOut.prototype.getUserproxyconfig = function() {
  return /** @type{?proto.server.UserProxyConfig} */ (
    jspb.Message.getWrapperField(this, proto.server.UserProxyConfig, 2));
};


/**
 * @param {?proto.server.UserProxyConfig|undefined} value
 * @return {!proto.server.UserProxyConfigProviderOut} returns this
*/
proto.server.UserProxyConfigProviderOut.prototype.setUserproxyconfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.UserProxyConfigProviderOut} returns this
 */
proto.server.UserProxyConfigProviderOut.prototype.clearUserproxyconfig = function() {
  return this.setUserproxyconfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.UserProxyConfigProviderOut.prototype.hasUserproxyconfig = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * map<string, MTranslate> MTranslateProxy = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.server.MTranslate>}
 */
proto.server.UserProxyConfigProviderOut.prototype.getMtranslateproxyMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.server.MTranslate>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.server.MTranslate));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.server.UserProxyConfigProviderOut} returns this
 */
proto.server.UserProxyConfigProviderOut.prototype.clearMtranslateproxyMap = function() {
  this.getMtranslateproxyMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.CompanyInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.server.CompanyInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.CompanyInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.CompanyInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stocktype: jspb.Message.getFieldWithDefault(msg, 2, ""),
    stockid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    companyname: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.CompanyInfo}
 */
proto.server.CompanyInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.CompanyInfo;
  return proto.server.CompanyInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.CompanyInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.CompanyInfo}
 */
proto.server.CompanyInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStocktype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStockid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.CompanyInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.CompanyInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.CompanyInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.CompanyInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStocktype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStockid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompanyname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 Id = 1;
 * @return {number}
 */
proto.server.CompanyInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.CompanyInfo} returns this
 */
proto.server.CompanyInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string StockType = 2;
 * @return {string}
 */
proto.server.CompanyInfo.prototype.getStocktype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.CompanyInfo} returns this
 */
proto.server.CompanyInfo.prototype.setStocktype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string StockId = 3;
 * @return {string}
 */
proto.server.CompanyInfo.prototype.getStockid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.CompanyInfo} returns this
 */
proto.server.CompanyInfo.prototype.setStockid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string CompanyName = 4;
 * @return {string}
 */
proto.server.CompanyInfo.prototype.getCompanyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.CompanyInfo} returns this
 */
proto.server.CompanyInfo.prototype.setCompanyname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.CompanyAlive.prototype.toObject = function(opt_includeInstance) {
  return proto.server.CompanyAlive.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.CompanyAlive} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.CompanyAlive.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyinfoid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    alivedate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    alreadyupdated: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.CompanyAlive}
 */
proto.server.CompanyAlive.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.CompanyAlive;
  return proto.server.CompanyAlive.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.CompanyAlive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.CompanyAlive}
 */
proto.server.CompanyAlive.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyinfoid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlivedate(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlreadyupdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.CompanyAlive.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.CompanyAlive.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.CompanyAlive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.CompanyAlive.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCompanyinfoid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAlivedate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAlreadyupdated();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int64 Id = 1;
 * @return {number}
 */
proto.server.CompanyAlive.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.CompanyAlive} returns this
 */
proto.server.CompanyAlive.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string CompanyInfoId = 2;
 * @return {string}
 */
proto.server.CompanyAlive.prototype.getCompanyinfoid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.CompanyAlive} returns this
 */
proto.server.CompanyAlive.prototype.setCompanyinfoid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string AliveDate = 3;
 * @return {string}
 */
proto.server.CompanyAlive.prototype.getAlivedate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.CompanyAlive} returns this
 */
proto.server.CompanyAlive.prototype.setAlivedate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool AlreadyUpdated = 4;
 * @return {boolean}
 */
proto.server.CompanyAlive.prototype.getAlreadyupdated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.CompanyAlive} returns this
 */
proto.server.CompanyAlive.prototype.setAlreadyupdated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MUserPassword.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MUserPassword.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MUserPassword} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MUserPassword.toObject = function(includeInstance, msg) {
  var f, obj = {
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MUserPassword}
 */
proto.server.MUserPassword.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MUserPassword;
  return proto.server.MUserPassword.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MUserPassword} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MUserPassword}
 */
proto.server.MUserPassword.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MUserPassword.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MUserPassword.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MUserPassword} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MUserPassword.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Password = 2;
 * @return {string}
 */
proto.server.MUserPassword.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MUserPassword} returns this
 */
proto.server.MUserPassword.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MUserBalance.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MUserBalance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MUserBalance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MUserBalance.toObject = function(includeInstance, msg) {
  var f, obj = {
    balanceMap: (f = msg.getBalanceMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MUserBalance}
 */
proto.server.MUserBalance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MUserBalance;
  return proto.server.MUserBalance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MUserBalance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MUserBalance}
 */
proto.server.MUserBalance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getBalanceMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt64, null, "", 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MUserBalance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MUserBalance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MUserBalance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MUserBalance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBalanceMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt64);
  }
};


/**
 * map<string, int64> Balance = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.server.MUserBalance.prototype.getBalanceMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.server.MUserBalance} returns this
 */
proto.server.MUserBalance.prototype.clearBalanceMap = function() {
  this.getBalanceMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MUserInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MUserInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MUserInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MUserInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    nickname: jspb.Message.getFieldWithDefault(msg, 6, ""),
    username: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MUserInfo}
 */
proto.server.MUserInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MUserInfo;
  return proto.server.MUserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MUserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MUserInfo}
 */
proto.server.MUserInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickname(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MUserInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MUserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MUserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MUserInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNickname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string Nickname = 6;
 * @return {string}
 */
proto.server.MUserInfo.prototype.getNickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MUserInfo} returns this
 */
proto.server.MUserInfo.prototype.setNickname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string UserName = 9;
 * @return {string}
 */
proto.server.MUserInfo.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MUserInfo} returns this
 */
proto.server.MUserInfo.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MUserSession.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MUserSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MUserSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MUserSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: jspb.Message.getFieldWithDefault(msg, 2, ""),
    botkey: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MUserSession}
 */
proto.server.MUserSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MUserSession;
  return proto.server.MUserSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MUserSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MUserSession}
 */
proto.server.MUserSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSession(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBotkey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MUserSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MUserSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MUserSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MUserSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBotkey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string Session = 2;
 * @return {string}
 */
proto.server.MUserSession.prototype.getSession = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MUserSession} returns this
 */
proto.server.MUserSession.prototype.setSession = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string BotKey = 3;
 * @return {string}
 */
proto.server.MUserSession.prototype.getBotkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MUserSession} returns this
 */
proto.server.MUserSession.prototype.setBotkey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.MUserBbs.repeatedFields_ = [3,4,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MUserBbs.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MUserBbs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MUserBbs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MUserBbs.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagline: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bio: jspb.Message.getFieldWithDefault(msg, 2, ""),
    followList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    fansList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    recentrepliesList: jspb.Message.toObjectList(msg.getRecentrepliesList(),
    proto.server.Reply.toObject, includeInstance),
    recentatsList: jspb.Message.toObjectList(msg.getRecentatsList(),
    proto.server.At.toObject, includeInstance),
    topicscollectedList: jspb.Message.toObjectList(msg.getTopicscollectedList(),
    proto.server.CollectTopic.toObject, includeInstance),
    issuperuser: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isblocked: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    validatecode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    resetcode: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MUserBbs}
 */
proto.server.MUserBbs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MUserBbs;
  return proto.server.MUserBbs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MUserBbs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MUserBbs}
 */
proto.server.MUserBbs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagline(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBio(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addFollow(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addFans(value);
      break;
    case 5:
      var value = new proto.server.Reply;
      reader.readMessage(value,proto.server.Reply.deserializeBinaryFromReader);
      msg.addRecentreplies(value);
      break;
    case 6:
      var value = new proto.server.At;
      reader.readMessage(value,proto.server.At.deserializeBinaryFromReader);
      msg.addRecentats(value);
      break;
    case 7:
      var value = new proto.server.CollectTopic;
      reader.readMessage(value,proto.server.CollectTopic.deserializeBinaryFromReader);
      msg.addTopicscollected(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIssuperuser(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsblocked(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidatecode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setResetcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MUserBbs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MUserBbs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MUserBbs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MUserBbs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagline();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBio();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFollowList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getFansList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getRecentrepliesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.server.Reply.serializeBinaryToWriter
    );
  }
  f = message.getRecentatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.server.At.serializeBinaryToWriter
    );
  }
  f = message.getTopicscollectedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.server.CollectTopic.serializeBinaryToWriter
    );
  }
  f = message.getIssuperuser();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsblocked();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getValidatecode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getResetcode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string Tagline = 1;
 * @return {string}
 */
proto.server.MUserBbs.prototype.getTagline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MUserBbs} returns this
 */
proto.server.MUserBbs.prototype.setTagline = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Bio = 2;
 * @return {string}
 */
proto.server.MUserBbs.prototype.getBio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MUserBbs} returns this
 */
proto.server.MUserBbs.prototype.setBio = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string Follow = 3;
 * @return {!Array<string>}
 */
proto.server.MUserBbs.prototype.getFollowList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.server.MUserBbs} returns this
 */
proto.server.MUserBbs.prototype.setFollowList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.server.MUserBbs} returns this
 */
proto.server.MUserBbs.prototype.addFollow = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.MUserBbs} returns this
 */
proto.server.MUserBbs.prototype.clearFollowList = function() {
  return this.setFollowList([]);
};


/**
 * repeated string Fans = 4;
 * @return {!Array<string>}
 */
proto.server.MUserBbs.prototype.getFansList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.server.MUserBbs} returns this
 */
proto.server.MUserBbs.prototype.setFansList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.server.MUserBbs} returns this
 */
proto.server.MUserBbs.prototype.addFans = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.MUserBbs} returns this
 */
proto.server.MUserBbs.prototype.clearFansList = function() {
  return this.setFansList([]);
};


/**
 * repeated Reply RecentReplies = 5;
 * @return {!Array<!proto.server.Reply>}
 */
proto.server.MUserBbs.prototype.getRecentrepliesList = function() {
  return /** @type{!Array<!proto.server.Reply>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.server.Reply, 5));
};


/**
 * @param {!Array<!proto.server.Reply>} value
 * @return {!proto.server.MUserBbs} returns this
*/
proto.server.MUserBbs.prototype.setRecentrepliesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.server.Reply=} opt_value
 * @param {number=} opt_index
 * @return {!proto.server.Reply}
 */
proto.server.MUserBbs.prototype.addRecentreplies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.server.Reply, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.MUserBbs} returns this
 */
proto.server.MUserBbs.prototype.clearRecentrepliesList = function() {
  return this.setRecentrepliesList([]);
};


/**
 * repeated At RecentAts = 6;
 * @return {!Array<!proto.server.At>}
 */
proto.server.MUserBbs.prototype.getRecentatsList = function() {
  return /** @type{!Array<!proto.server.At>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.server.At, 6));
};


/**
 * @param {!Array<!proto.server.At>} value
 * @return {!proto.server.MUserBbs} returns this
*/
proto.server.MUserBbs.prototype.setRecentatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.server.At=} opt_value
 * @param {number=} opt_index
 * @return {!proto.server.At}
 */
proto.server.MUserBbs.prototype.addRecentats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.server.At, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.MUserBbs} returns this
 */
proto.server.MUserBbs.prototype.clearRecentatsList = function() {
  return this.setRecentatsList([]);
};


/**
 * repeated CollectTopic TopicsCollected = 7;
 * @return {!Array<!proto.server.CollectTopic>}
 */
proto.server.MUserBbs.prototype.getTopicscollectedList = function() {
  return /** @type{!Array<!proto.server.CollectTopic>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.server.CollectTopic, 7));
};


/**
 * @param {!Array<!proto.server.CollectTopic>} value
 * @return {!proto.server.MUserBbs} returns this
*/
proto.server.MUserBbs.prototype.setTopicscollectedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.server.CollectTopic=} opt_value
 * @param {number=} opt_index
 * @return {!proto.server.CollectTopic}
 */
proto.server.MUserBbs.prototype.addTopicscollected = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.server.CollectTopic, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.MUserBbs} returns this
 */
proto.server.MUserBbs.prototype.clearTopicscollectedList = function() {
  return this.setTopicscollectedList([]);
};


/**
 * optional bool IsSuperuser = 8;
 * @return {boolean}
 */
proto.server.MUserBbs.prototype.getIssuperuser = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.MUserBbs} returns this
 */
proto.server.MUserBbs.prototype.setIssuperuser = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool IsActive = 9;
 * @return {boolean}
 */
proto.server.MUserBbs.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.MUserBbs} returns this
 */
proto.server.MUserBbs.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool IsBlocked = 10;
 * @return {boolean}
 */
proto.server.MUserBbs.prototype.getIsblocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.MUserBbs} returns this
 */
proto.server.MUserBbs.prototype.setIsblocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string ValidateCode = 11;
 * @return {string}
 */
proto.server.MUserBbs.prototype.getValidatecode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MUserBbs} returns this
 */
proto.server.MUserBbs.prototype.setValidatecode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string ResetCode = 12;
 * @return {string}
 */
proto.server.MUserBbs.prototype.getResetcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MUserBbs} returns this
 */
proto.server.MUserBbs.prototype.setResetcode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.CollectTopic.prototype.toObject = function(opt_includeInstance) {
  return proto.server.CollectTopic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.CollectTopic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.CollectTopic.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.CollectTopic}
 */
proto.server.CollectTopic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.CollectTopic;
  return proto.server.CollectTopic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.CollectTopic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.CollectTopic}
 */
proto.server.CollectTopic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.CollectTopic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.CollectTopic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.CollectTopic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.CollectTopic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.At.prototype.toObject = function(opt_includeInstance) {
  return proto.server.At.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.At} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.At.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.At}
 */
proto.server.At.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.At;
  return proto.server.At.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.At} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.At}
 */
proto.server.At.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.At.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.At.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.At} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.At.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.Reply.prototype.toObject = function(opt_includeInstance) {
  return proto.server.Reply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.Reply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.Reply.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.Reply}
 */
proto.server.Reply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.Reply;
  return proto.server.Reply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.Reply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.Reply}
 */
proto.server.Reply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.Reply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.Reply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.Reply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.Reply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MUser.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    muserinfo: (f = msg.getMuserinfo()) && proto.server.MUserInfo.toObject(includeInstance, f),
    muserpassword: (f = msg.getMuserpassword()) && proto.server.MUserPassword.toObject(includeInstance, f),
    muserbalance: (f = msg.getMuserbalance()) && proto.server.MUserBalance.toObject(includeInstance, f),
    musersession: (f = msg.getMusersession()) && proto.server.MUserSession.toObject(includeInstance, f),
    musertime: (f = msg.getMusertime()) && proto.server.MUserTime.toObject(includeInstance, f),
    muserproxy: (f = msg.getMuserproxy()) && proto.server.MUserProxy.toObject(includeInstance, f),
    muserbbs: (f = msg.getMuserbbs()) && proto.server.MUserBbs.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MUser}
 */
proto.server.MUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MUser;
  return proto.server.MUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MUser}
 */
proto.server.MUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.server.MUserInfo;
      reader.readMessage(value,proto.server.MUserInfo.deserializeBinaryFromReader);
      msg.setMuserinfo(value);
      break;
    case 3:
      var value = new proto.server.MUserPassword;
      reader.readMessage(value,proto.server.MUserPassword.deserializeBinaryFromReader);
      msg.setMuserpassword(value);
      break;
    case 4:
      var value = new proto.server.MUserBalance;
      reader.readMessage(value,proto.server.MUserBalance.deserializeBinaryFromReader);
      msg.setMuserbalance(value);
      break;
    case 5:
      var value = new proto.server.MUserSession;
      reader.readMessage(value,proto.server.MUserSession.deserializeBinaryFromReader);
      msg.setMusersession(value);
      break;
    case 6:
      var value = new proto.server.MUserTime;
      reader.readMessage(value,proto.server.MUserTime.deserializeBinaryFromReader);
      msg.setMusertime(value);
      break;
    case 7:
      var value = new proto.server.MUserProxy;
      reader.readMessage(value,proto.server.MUserProxy.deserializeBinaryFromReader);
      msg.setMuserproxy(value);
      break;
    case 8:
      var value = new proto.server.MUserBbs;
      reader.readMessage(value,proto.server.MUserBbs.deserializeBinaryFromReader);
      msg.setMuserbbs(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMuserinfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.server.MUserInfo.serializeBinaryToWriter
    );
  }
  f = message.getMuserpassword();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.server.MUserPassword.serializeBinaryToWriter
    );
  }
  f = message.getMuserbalance();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.server.MUserBalance.serializeBinaryToWriter
    );
  }
  f = message.getMusersession();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.server.MUserSession.serializeBinaryToWriter
    );
  }
  f = message.getMusertime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.server.MUserTime.serializeBinaryToWriter
    );
  }
  f = message.getMuserproxy();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.server.MUserProxy.serializeBinaryToWriter
    );
  }
  f = message.getMuserbbs();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.server.MUserBbs.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional MUserInfo MUserInfo = 1;
 * @return {?proto.server.MUserInfo}
 */
proto.server.MUser.prototype.getMuserinfo = function() {
  return /** @type{?proto.server.MUserInfo} */ (
    jspb.Message.getWrapperField(this, proto.server.MUserInfo, 1));
};


/**
 * @param {?proto.server.MUserInfo|undefined} value
 * @return {!proto.server.MUser} returns this
*/
proto.server.MUser.prototype.setMuserinfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.MUser} returns this
 */
proto.server.MUser.prototype.clearMuserinfo = function() {
  return this.setMuserinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.MUser.prototype.hasMuserinfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MUserPassword MUserPassword = 3;
 * @return {?proto.server.MUserPassword}
 */
proto.server.MUser.prototype.getMuserpassword = function() {
  return /** @type{?proto.server.MUserPassword} */ (
    jspb.Message.getWrapperField(this, proto.server.MUserPassword, 3));
};


/**
 * @param {?proto.server.MUserPassword|undefined} value
 * @return {!proto.server.MUser} returns this
*/
proto.server.MUser.prototype.setMuserpassword = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.MUser} returns this
 */
proto.server.MUser.prototype.clearMuserpassword = function() {
  return this.setMuserpassword(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.MUser.prototype.hasMuserpassword = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional MUserBalance MUserBalance = 4;
 * @return {?proto.server.MUserBalance}
 */
proto.server.MUser.prototype.getMuserbalance = function() {
  return /** @type{?proto.server.MUserBalance} */ (
    jspb.Message.getWrapperField(this, proto.server.MUserBalance, 4));
};


/**
 * @param {?proto.server.MUserBalance|undefined} value
 * @return {!proto.server.MUser} returns this
*/
proto.server.MUser.prototype.setMuserbalance = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.MUser} returns this
 */
proto.server.MUser.prototype.clearMuserbalance = function() {
  return this.setMuserbalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.MUser.prototype.hasMuserbalance = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional MUserSession MUserSession = 5;
 * @return {?proto.server.MUserSession}
 */
proto.server.MUser.prototype.getMusersession = function() {
  return /** @type{?proto.server.MUserSession} */ (
    jspb.Message.getWrapperField(this, proto.server.MUserSession, 5));
};


/**
 * @param {?proto.server.MUserSession|undefined} value
 * @return {!proto.server.MUser} returns this
*/
proto.server.MUser.prototype.setMusersession = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.MUser} returns this
 */
proto.server.MUser.prototype.clearMusersession = function() {
  return this.setMusersession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.MUser.prototype.hasMusersession = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional MUserTime MUserTime = 6;
 * @return {?proto.server.MUserTime}
 */
proto.server.MUser.prototype.getMusertime = function() {
  return /** @type{?proto.server.MUserTime} */ (
    jspb.Message.getWrapperField(this, proto.server.MUserTime, 6));
};


/**
 * @param {?proto.server.MUserTime|undefined} value
 * @return {!proto.server.MUser} returns this
*/
proto.server.MUser.prototype.setMusertime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.MUser} returns this
 */
proto.server.MUser.prototype.clearMusertime = function() {
  return this.setMusertime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.MUser.prototype.hasMusertime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional MUserProxy MUserProxy = 7;
 * @return {?proto.server.MUserProxy}
 */
proto.server.MUser.prototype.getMuserproxy = function() {
  return /** @type{?proto.server.MUserProxy} */ (
    jspb.Message.getWrapperField(this, proto.server.MUserProxy, 7));
};


/**
 * @param {?proto.server.MUserProxy|undefined} value
 * @return {!proto.server.MUser} returns this
*/
proto.server.MUser.prototype.setMuserproxy = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.MUser} returns this
 */
proto.server.MUser.prototype.clearMuserproxy = function() {
  return this.setMuserproxy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.MUser.prototype.hasMuserproxy = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional MUserBbs MUserBbs = 8;
 * @return {?proto.server.MUserBbs}
 */
proto.server.MUser.prototype.getMuserbbs = function() {
  return /** @type{?proto.server.MUserBbs} */ (
    jspb.Message.getWrapperField(this, proto.server.MUserBbs, 8));
};


/**
 * @param {?proto.server.MUserBbs|undefined} value
 * @return {!proto.server.MUser} returns this
*/
proto.server.MUser.prototype.setMuserbbs = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.MUser} returns this
 */
proto.server.MUser.prototype.clearMuserbbs = function() {
  return this.setMuserbbs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.MUser.prototype.hasMuserbbs = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string Id = 9;
 * @return {string}
 */
proto.server.MUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MUser} returns this
 */
proto.server.MUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MUserTime.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MUserTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MUserTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MUserTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    createtime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lastloginintime: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MUserTime}
 */
proto.server.MUserTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MUserTime;
  return proto.server.MUserTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MUserTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MUserTime}
 */
proto.server.MUserTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatetime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastloginintime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MUserTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MUserTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MUserTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MUserTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatetime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLastloginintime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 CreateTime = 1;
 * @return {number}
 */
proto.server.MUserTime.prototype.getCreatetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MUserTime} returns this
 */
proto.server.MUserTime.prototype.setCreatetime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 LastLoginInTime = 2;
 * @return {number}
 */
proto.server.MUserTime.prototype.getLastloginintime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MUserTime} returns this
 */
proto.server.MUserTime.prototype.setLastloginintime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.SalePlanProvider.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.SalePlanProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.server.SalePlanProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.SalePlanProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.SalePlanProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentplan: (f = msg.getPaymentplan()) && proto.server.PaymentPlan.toObject(includeInstance, f),
    paymentmethod: (f = msg.getPaymentmethod()) && proto.server.PaymentMethod.toObject(includeInstance, f),
    paymentplanlistList: jspb.Message.toObjectList(msg.getPaymentplanlistList(),
    proto.server.PaymentPlan.toObject, includeInstance),
    mpaymentmethod: (f = msg.getMpaymentmethod()) && proto.server.MPaymentMethod.toObject(includeInstance, f),
    paymentmethodlistList: jspb.Message.toObjectList(msg.getPaymentmethodlistList(),
    proto.server.PaymentMethod.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.SalePlanProvider}
 */
proto.server.SalePlanProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.SalePlanProvider;
  return proto.server.SalePlanProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.SalePlanProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.SalePlanProvider}
 */
proto.server.SalePlanProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.server.PaymentPlan;
      reader.readMessage(value,proto.server.PaymentPlan.deserializeBinaryFromReader);
      msg.setPaymentplan(value);
      break;
    case 3:
      var value = new proto.server.PaymentMethod;
      reader.readMessage(value,proto.server.PaymentMethod.deserializeBinaryFromReader);
      msg.setPaymentmethod(value);
      break;
    case 4:
      var value = new proto.server.PaymentPlan;
      reader.readMessage(value,proto.server.PaymentPlan.deserializeBinaryFromReader);
      msg.addPaymentplanlist(value);
      break;
    case 6:
      var value = new proto.server.MPaymentMethod;
      reader.readMessage(value,proto.server.MPaymentMethod.deserializeBinaryFromReader);
      msg.setMpaymentmethod(value);
      break;
    case 5:
      var value = new proto.server.PaymentMethod;
      reader.readMessage(value,proto.server.PaymentMethod.deserializeBinaryFromReader);
      msg.addPaymentmethodlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.SalePlanProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.SalePlanProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.SalePlanProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.SalePlanProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentplan();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.server.PaymentPlan.serializeBinaryToWriter
    );
  }
  f = message.getPaymentmethod();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.server.PaymentMethod.serializeBinaryToWriter
    );
  }
  f = message.getPaymentplanlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.server.PaymentPlan.serializeBinaryToWriter
    );
  }
  f = message.getMpaymentmethod();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.server.MPaymentMethod.serializeBinaryToWriter
    );
  }
  f = message.getPaymentmethodlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.server.PaymentMethod.serializeBinaryToWriter
    );
  }
};


/**
 * optional PaymentPlan PaymentPlan = 2;
 * @return {?proto.server.PaymentPlan}
 */
proto.server.SalePlanProvider.prototype.getPaymentplan = function() {
  return /** @type{?proto.server.PaymentPlan} */ (
    jspb.Message.getWrapperField(this, proto.server.PaymentPlan, 2));
};


/**
 * @param {?proto.server.PaymentPlan|undefined} value
 * @return {!proto.server.SalePlanProvider} returns this
*/
proto.server.SalePlanProvider.prototype.setPaymentplan = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.SalePlanProvider} returns this
 */
proto.server.SalePlanProvider.prototype.clearPaymentplan = function() {
  return this.setPaymentplan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.SalePlanProvider.prototype.hasPaymentplan = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PaymentMethod PaymentMethod = 3;
 * @return {?proto.server.PaymentMethod}
 */
proto.server.SalePlanProvider.prototype.getPaymentmethod = function() {
  return /** @type{?proto.server.PaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto.server.PaymentMethod, 3));
};


/**
 * @param {?proto.server.PaymentMethod|undefined} value
 * @return {!proto.server.SalePlanProvider} returns this
*/
proto.server.SalePlanProvider.prototype.setPaymentmethod = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.SalePlanProvider} returns this
 */
proto.server.SalePlanProvider.prototype.clearPaymentmethod = function() {
  return this.setPaymentmethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.SalePlanProvider.prototype.hasPaymentmethod = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated PaymentPlan PaymentPlanList = 4;
 * @return {!Array<!proto.server.PaymentPlan>}
 */
proto.server.SalePlanProvider.prototype.getPaymentplanlistList = function() {
  return /** @type{!Array<!proto.server.PaymentPlan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.server.PaymentPlan, 4));
};


/**
 * @param {!Array<!proto.server.PaymentPlan>} value
 * @return {!proto.server.SalePlanProvider} returns this
*/
proto.server.SalePlanProvider.prototype.setPaymentplanlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.server.PaymentPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.server.PaymentPlan}
 */
proto.server.SalePlanProvider.prototype.addPaymentplanlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.server.PaymentPlan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.SalePlanProvider} returns this
 */
proto.server.SalePlanProvider.prototype.clearPaymentplanlistList = function() {
  return this.setPaymentplanlistList([]);
};


/**
 * optional MPaymentMethod MPaymentMethod = 6;
 * @return {?proto.server.MPaymentMethod}
 */
proto.server.SalePlanProvider.prototype.getMpaymentmethod = function() {
  return /** @type{?proto.server.MPaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto.server.MPaymentMethod, 6));
};


/**
 * @param {?proto.server.MPaymentMethod|undefined} value
 * @return {!proto.server.SalePlanProvider} returns this
*/
proto.server.SalePlanProvider.prototype.setMpaymentmethod = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.SalePlanProvider} returns this
 */
proto.server.SalePlanProvider.prototype.clearMpaymentmethod = function() {
  return this.setMpaymentmethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.SalePlanProvider.prototype.hasMpaymentmethod = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated PaymentMethod PaymentMethodList = 5;
 * @return {!Array<!proto.server.PaymentMethod>}
 */
proto.server.SalePlanProvider.prototype.getPaymentmethodlistList = function() {
  return /** @type{!Array<!proto.server.PaymentMethod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.server.PaymentMethod, 5));
};


/**
 * @param {!Array<!proto.server.PaymentMethod>} value
 * @return {!proto.server.SalePlanProvider} returns this
*/
proto.server.SalePlanProvider.prototype.setPaymentmethodlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.server.PaymentMethod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.server.PaymentMethod}
 */
proto.server.SalePlanProvider.prototype.addPaymentmethodlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.server.PaymentMethod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.SalePlanProvider} returns this
 */
proto.server.SalePlanProvider.prototype.clearPaymentmethodlistList = function() {
  return this.setPaymentmethodlistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.PaymentPlan.prototype.toObject = function(opt_includeInstance) {
  return proto.server.PaymentPlan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.PaymentPlan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.PaymentPlan.toObject = function(includeInstance, msg) {
  var f, obj = {
    mcurrency: (f = msg.getMcurrency()) && proto.server.MCurrency.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.PaymentPlan}
 */
proto.server.PaymentPlan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.PaymentPlan;
  return proto.server.PaymentPlan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.PaymentPlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.PaymentPlan}
 */
proto.server.PaymentPlan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.server.MCurrency;
      reader.readMessage(value,proto.server.MCurrency.deserializeBinaryFromReader);
      msg.setMcurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.PaymentPlan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.PaymentPlan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.PaymentPlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.PaymentPlan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMcurrency();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.server.MCurrency.serializeBinaryToWriter
    );
  }
};


/**
 * optional MCurrency MCurrency = 2;
 * @return {?proto.server.MCurrency}
 */
proto.server.PaymentPlan.prototype.getMcurrency = function() {
  return /** @type{?proto.server.MCurrency} */ (
    jspb.Message.getWrapperField(this, proto.server.MCurrency, 2));
};


/**
 * @param {?proto.server.MCurrency|undefined} value
 * @return {!proto.server.PaymentPlan} returns this
*/
proto.server.PaymentPlan.prototype.setMcurrency = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.PaymentPlan} returns this
 */
proto.server.PaymentPlan.prototype.clearMcurrency = function() {
  return this.setMcurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.PaymentPlan.prototype.hasMcurrency = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MPaymentMethod.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MPaymentMethod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MPaymentMethod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MPaymentMethod.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rank: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    paymentmethodcode: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MPaymentMethod}
 */
proto.server.MPaymentMethod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MPaymentMethod;
  return proto.server.MPaymentMethod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MPaymentMethod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MPaymentMethod}
 */
proto.server.MPaymentMethod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRank(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 5:
      var value = /** @type {!proto.server.PaymentMethodCode} */ (reader.readEnum());
      msg.setPaymentmethodcode(value);
      break;
    case 6:
      var value = /** @type {!proto.server.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MPaymentMethod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MPaymentMethod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MPaymentMethod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MPaymentMethod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPaymentmethodcode();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string Id = 1;
 * @return {string}
 */
proto.server.MPaymentMethod.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MPaymentMethod} returns this
 */
proto.server.MPaymentMethod.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 rank = 2;
 * @return {number}
 */
proto.server.MPaymentMethod.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MPaymentMethod} returns this
 */
proto.server.MPaymentMethod.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string Title = 3;
 * @return {string}
 */
proto.server.MPaymentMethod.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MPaymentMethod} returns this
 */
proto.server.MPaymentMethod.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string SubTitle = 4;
 * @return {string}
 */
proto.server.MPaymentMethod.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MPaymentMethod} returns this
 */
proto.server.MPaymentMethod.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional PaymentMethodCode PaymentMethodCode = 5;
 * @return {!proto.server.PaymentMethodCode}
 */
proto.server.MPaymentMethod.prototype.getPaymentmethodcode = function() {
  return /** @type {!proto.server.PaymentMethodCode} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.server.PaymentMethodCode} value
 * @return {!proto.server.MPaymentMethod} returns this
 */
proto.server.MPaymentMethod.prototype.setPaymentmethodcode = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional Status Status = 6;
 * @return {!proto.server.Status}
 */
proto.server.MPaymentMethod.prototype.getStatus = function() {
  return /** @type {!proto.server.Status} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.server.Status} value
 * @return {!proto.server.MPaymentMethod} returns this
 */
proto.server.MPaymentMethod.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.MShop.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MShop.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MShop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MShop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MShop.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    countyList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    mproductlistinfo: (f = msg.getMproductlistinfo()) && proto.server.MProductListInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MShop}
 */
proto.server.MShop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MShop;
  return proto.server.MShop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MShop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MShop}
 */
proto.server.MShop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCounty(value);
      break;
    case 3:
      var value = new proto.server.MProductListInfo;
      reader.readMessage(value,proto.server.MProductListInfo.deserializeBinaryFromReader);
      msg.setMproductlistinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MShop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MShop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MShop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MShop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountyList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getMproductlistinfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.server.MProductListInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.server.MShop.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MShop} returns this
 */
proto.server.MShop.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string County = 2;
 * @return {!Array<string>}
 */
proto.server.MShop.prototype.getCountyList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.server.MShop} returns this
 */
proto.server.MShop.prototype.setCountyList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.server.MShop} returns this
 */
proto.server.MShop.prototype.addCounty = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.MShop} returns this
 */
proto.server.MShop.prototype.clearCountyList = function() {
  return this.setCountyList([]);
};


/**
 * optional MProductListInfo MProductListInfo = 3;
 * @return {?proto.server.MProductListInfo}
 */
proto.server.MShop.prototype.getMproductlistinfo = function() {
  return /** @type{?proto.server.MProductListInfo} */ (
    jspb.Message.getWrapperField(this, proto.server.MProductListInfo, 3));
};


/**
 * @param {?proto.server.MProductListInfo|undefined} value
 * @return {!proto.server.MShop} returns this
*/
proto.server.MShop.prototype.setMproductlistinfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.MShop} returns this
 */
proto.server.MShop.prototype.clearMproductlistinfo = function() {
  return this.setMproductlistinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.MShop.prototype.hasMproductlistinfo = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.MProductListInfo.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MProductListInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MProductListInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MProductListInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MProductListInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    mproductidlistList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    mproductlistList: jspb.Message.toObjectList(msg.getMproductlistList(),
    proto.server.MProduct.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MProductListInfo}
 */
proto.server.MProductListInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MProductListInfo;
  return proto.server.MProductListInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MProductListInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MProductListInfo}
 */
proto.server.MProductListInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addMproductidlist(value);
      break;
    case 2:
      var value = new proto.server.MProduct;
      reader.readMessage(value,proto.server.MProduct.deserializeBinaryFromReader);
      msg.addMproductlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MProductListInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MProductListInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MProductListInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MProductListInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMproductidlistList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getMproductlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.server.MProduct.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string MProductIdList = 1;
 * @return {!Array<string>}
 */
proto.server.MProductListInfo.prototype.getMproductidlistList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.server.MProductListInfo} returns this
 */
proto.server.MProductListInfo.prototype.setMproductidlistList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.server.MProductListInfo} returns this
 */
proto.server.MProductListInfo.prototype.addMproductidlist = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.MProductListInfo} returns this
 */
proto.server.MProductListInfo.prototype.clearMproductidlistList = function() {
  return this.setMproductidlistList([]);
};


/**
 * repeated MProduct MProductList = 2;
 * @return {!Array<!proto.server.MProduct>}
 */
proto.server.MProductListInfo.prototype.getMproductlistList = function() {
  return /** @type{!Array<!proto.server.MProduct>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.server.MProduct, 2));
};


/**
 * @param {!Array<!proto.server.MProduct>} value
 * @return {!proto.server.MProductListInfo} returns this
*/
proto.server.MProductListInfo.prototype.setMproductlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.server.MProduct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.server.MProduct}
 */
proto.server.MProductListInfo.prototype.addMproductlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.server.MProduct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.MProductListInfo} returns this
 */
proto.server.MProductListInfo.prototype.clearMproductlistList = function() {
  return this.setMproductlistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MCurrency.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MCurrency.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MCurrency} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MCurrency.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 2, ""),
    symbolstripe: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MCurrency}
 */
proto.server.MCurrency.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MCurrency;
  return proto.server.MCurrency.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MCurrency} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MCurrency}
 */
proto.server.MCurrency.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolstripe(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MCurrency.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MCurrency.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MCurrency} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MCurrency.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSymbolstripe();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.server.MCurrency.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MCurrency} returns this
 */
proto.server.MCurrency.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Symbol = 2;
 * @return {string}
 */
proto.server.MCurrency.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MCurrency} returns this
 */
proto.server.MCurrency.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string SymbolStripe = 3;
 * @return {string}
 */
proto.server.MCurrency.prototype.getSymbolstripe = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MCurrency} returns this
 */
proto.server.MCurrency.prototype.setSymbolstripe = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MPaymentPackage.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MPaymentPackage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MPaymentPackage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MPaymentPackage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    originalprice: jspb.Message.getFieldWithDefault(msg, 5, 0),
    price: jspb.Message.getFieldWithDefault(msg, 6, 0),
    length: jspb.Message.getFieldWithDefault(msg, 7, 0),
    billingcycletitle: jspb.Message.getFieldWithDefault(msg, 12, ""),
    title: jspb.Message.getFieldWithDefault(msg, 13, ""),
    rank: jspb.Message.getFieldWithDefault(msg, 14, 0),
    mcurrencyname: jspb.Message.getFieldWithDefault(msg, 16, ""),
    mcurrency: (f = msg.getMcurrency()) && proto.server.MCurrency.toObject(includeInstance, f),
    mproductplantype: jspb.Message.getFieldWithDefault(msg, 8, 0),
    mproxyplan: (f = msg.getMproxyplan()) && proto.server.MProxyPlan.toObject(includeInstance, f),
    mtrafficplan: (f = msg.getMtrafficplan()) && proto.server.MTrafficPlan.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MPaymentPackage}
 */
proto.server.MPaymentPackage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MPaymentPackage;
  return proto.server.MPaymentPackage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MPaymentPackage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MPaymentPackage}
 */
proto.server.MPaymentPackage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOriginalprice(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLength(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingcycletitle(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRank(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setMcurrencyname(value);
      break;
    case 17:
      var value = new proto.server.MCurrency;
      reader.readMessage(value,proto.server.MCurrency.deserializeBinaryFromReader);
      msg.setMcurrency(value);
      break;
    case 8:
      var value = /** @type {!proto.server.MProductPlanType} */ (reader.readEnum());
      msg.setMproductplantype(value);
      break;
    case 9:
      var value = new proto.server.MProxyPlan;
      reader.readMessage(value,proto.server.MProxyPlan.deserializeBinaryFromReader);
      msg.setMproxyplan(value);
      break;
    case 10:
      var value = new proto.server.MTrafficPlan;
      reader.readMessage(value,proto.server.MTrafficPlan.deserializeBinaryFromReader);
      msg.setMtrafficplan(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MPaymentPackage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MPaymentPackage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MPaymentPackage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MPaymentPackage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOriginalprice();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getLength();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getBillingcycletitle();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getMcurrencyname();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getMcurrency();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.server.MCurrency.serializeBinaryToWriter
    );
  }
  f = message.getMproductplantype();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getMproxyplan();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.server.MProxyPlan.serializeBinaryToWriter
    );
  }
  f = message.getMtrafficplan();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.server.MTrafficPlan.serializeBinaryToWriter
    );
  }
};


/**
 * optional string Id = 1;
 * @return {string}
 */
proto.server.MPaymentPackage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MPaymentPackage} returns this
 */
proto.server.MPaymentPackage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 OriginalPrice = 5;
 * @return {number}
 */
proto.server.MPaymentPackage.prototype.getOriginalprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MPaymentPackage} returns this
 */
proto.server.MPaymentPackage.prototype.setOriginalprice = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 Price = 6;
 * @return {number}
 */
proto.server.MPaymentPackage.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MPaymentPackage} returns this
 */
proto.server.MPaymentPackage.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 Length = 7;
 * @return {number}
 */
proto.server.MPaymentPackage.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MPaymentPackage} returns this
 */
proto.server.MPaymentPackage.prototype.setLength = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string BillingCycleTitle = 12;
 * @return {string}
 */
proto.server.MPaymentPackage.prototype.getBillingcycletitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MPaymentPackage} returns this
 */
proto.server.MPaymentPackage.prototype.setBillingcycletitle = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string Title = 13;
 * @return {string}
 */
proto.server.MPaymentPackage.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MPaymentPackage} returns this
 */
proto.server.MPaymentPackage.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int64 Rank = 14;
 * @return {number}
 */
proto.server.MPaymentPackage.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MPaymentPackage} returns this
 */
proto.server.MPaymentPackage.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string MCurrencyName = 16;
 * @return {string}
 */
proto.server.MPaymentPackage.prototype.getMcurrencyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MPaymentPackage} returns this
 */
proto.server.MPaymentPackage.prototype.setMcurrencyname = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional MCurrency MCurrency = 17;
 * @return {?proto.server.MCurrency}
 */
proto.server.MPaymentPackage.prototype.getMcurrency = function() {
  return /** @type{?proto.server.MCurrency} */ (
    jspb.Message.getWrapperField(this, proto.server.MCurrency, 17));
};


/**
 * @param {?proto.server.MCurrency|undefined} value
 * @return {!proto.server.MPaymentPackage} returns this
*/
proto.server.MPaymentPackage.prototype.setMcurrency = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.MPaymentPackage} returns this
 */
proto.server.MPaymentPackage.prototype.clearMcurrency = function() {
  return this.setMcurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.MPaymentPackage.prototype.hasMcurrency = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional MProductPlanType MProductPlanType = 8;
 * @return {!proto.server.MProductPlanType}
 */
proto.server.MPaymentPackage.prototype.getMproductplantype = function() {
  return /** @type {!proto.server.MProductPlanType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.server.MProductPlanType} value
 * @return {!proto.server.MPaymentPackage} returns this
 */
proto.server.MPaymentPackage.prototype.setMproductplantype = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional MProxyPlan MProxyPlan = 9;
 * @return {?proto.server.MProxyPlan}
 */
proto.server.MPaymentPackage.prototype.getMproxyplan = function() {
  return /** @type{?proto.server.MProxyPlan} */ (
    jspb.Message.getWrapperField(this, proto.server.MProxyPlan, 9));
};


/**
 * @param {?proto.server.MProxyPlan|undefined} value
 * @return {!proto.server.MPaymentPackage} returns this
*/
proto.server.MPaymentPackage.prototype.setMproxyplan = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.MPaymentPackage} returns this
 */
proto.server.MPaymentPackage.prototype.clearMproxyplan = function() {
  return this.setMproxyplan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.MPaymentPackage.prototype.hasMproxyplan = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional MTrafficPlan MTrafficPlan = 10;
 * @return {?proto.server.MTrafficPlan}
 */
proto.server.MPaymentPackage.prototype.getMtrafficplan = function() {
  return /** @type{?proto.server.MTrafficPlan} */ (
    jspb.Message.getWrapperField(this, proto.server.MTrafficPlan, 10));
};


/**
 * @param {?proto.server.MTrafficPlan|undefined} value
 * @return {!proto.server.MPaymentPackage} returns this
*/
proto.server.MPaymentPackage.prototype.setMtrafficplan = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.MPaymentPackage} returns this
 */
proto.server.MPaymentPackage.prototype.clearMtrafficplan = function() {
  return this.setMtrafficplan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.MPaymentPackage.prototype.hasMtrafficplan = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MSystem.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MSystem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MSystem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MSystem.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mtranslateproxyMap: (f = msg.getMtranslateproxyMap()) ? f.toObject(includeInstance, proto.server.MTranslate.toObject) : [],
    proxyconfig: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MSystem}
 */
proto.server.MSystem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MSystem;
  return proto.server.MSystem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MSystem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MSystem}
 */
proto.server.MSystem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = msg.getMtranslateproxyMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.server.MTranslate.deserializeBinaryFromReader, "", new proto.server.MTranslate());
         });
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProxyconfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MSystem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MSystem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MSystem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MSystem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMtranslateproxyMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.server.MTranslate.serializeBinaryToWriter);
  }
  f = message.getProxyconfig();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.server.MSystem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MSystem} returns this
 */
proto.server.MSystem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, MTranslate> MTranslateProxy = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.server.MTranslate>}
 */
proto.server.MSystem.prototype.getMtranslateproxyMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.server.MTranslate>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.server.MTranslate));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.server.MSystem} returns this
 */
proto.server.MSystem.prototype.clearMtranslateproxyMap = function() {
  this.getMtranslateproxyMap().clear();
  return this;};


/**
 * optional string ProxyConfig = 3;
 * @return {string}
 */
proto.server.MSystem.prototype.getProxyconfig = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MSystem} returns this
 */
proto.server.MSystem.prototype.setProxyconfig = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    publishtime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    platform: jspb.Message.getFieldWithDefault(msg, 2, ""),
    currentversion: jspb.Message.getFieldWithDefault(msg, 3, ""),
    minimumversion: jspb.Message.getFieldWithDefault(msg, 4, ""),
    feature: jspb.Message.getFieldWithDefault(msg, 5, ""),
    resourceurl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    packagesize: jspb.Message.getFieldWithDefault(msg, 7, 0),
    md5: jspb.Message.getFieldWithDefault(msg, 8, ""),
    title: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MUpdate}
 */
proto.server.MUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MUpdate;
  return proto.server.MUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MUpdate}
 */
proto.server.MUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPublishtime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlatform(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentversion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMinimumversion(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeature(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceurl(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPackagesize(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMd5(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPublishtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPlatform();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrentversion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMinimumversion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFeature();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getResourceurl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPackagesize();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getMd5();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int64 PublishTime = 1;
 * @return {number}
 */
proto.server.MUpdate.prototype.getPublishtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MUpdate} returns this
 */
proto.server.MUpdate.prototype.setPublishtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Platform = 2;
 * @return {string}
 */
proto.server.MUpdate.prototype.getPlatform = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MUpdate} returns this
 */
proto.server.MUpdate.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string CurrentVersion = 3;
 * @return {string}
 */
proto.server.MUpdate.prototype.getCurrentversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MUpdate} returns this
 */
proto.server.MUpdate.prototype.setCurrentversion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string MinimumVersion = 4;
 * @return {string}
 */
proto.server.MUpdate.prototype.getMinimumversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MUpdate} returns this
 */
proto.server.MUpdate.prototype.setMinimumversion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Feature = 5;
 * @return {string}
 */
proto.server.MUpdate.prototype.getFeature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MUpdate} returns this
 */
proto.server.MUpdate.prototype.setFeature = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ResourceUrl = 6;
 * @return {string}
 */
proto.server.MUpdate.prototype.getResourceurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MUpdate} returns this
 */
proto.server.MUpdate.prototype.setResourceurl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 PackageSize = 7;
 * @return {number}
 */
proto.server.MUpdate.prototype.getPackagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MUpdate} returns this
 */
proto.server.MUpdate.prototype.setPackagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string Md5 = 8;
 * @return {string}
 */
proto.server.MUpdate.prototype.getMd5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MUpdate} returns this
 */
proto.server.MUpdate.prototype.setMd5 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string Title = 9;
 * @return {string}
 */
proto.server.MUpdate.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MUpdate} returns this
 */
proto.server.MUpdate.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MTranslate.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MTranslate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MTranslate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MTranslate.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataMap: (f = msg.getDataMap()) ? f.toObject(includeInstance, undefined) : [],
    proxyflagcode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    backgroundcolor: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MTranslate}
 */
proto.server.MTranslate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MTranslate;
  return proto.server.MTranslate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MTranslate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MTranslate}
 */
proto.server.MTranslate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProxyflagcode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundcolor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MTranslate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MTranslate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MTranslate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MTranslate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getProxyflagcode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBackgroundcolor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * map<string, string> Data = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.server.MTranslate.prototype.getDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.server.MTranslate} returns this
 */
proto.server.MTranslate.prototype.clearDataMap = function() {
  this.getDataMap().clear();
  return this;};


/**
 * optional string ProxyFlagCode = 2;
 * @return {string}
 */
proto.server.MTranslate.prototype.getProxyflagcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MTranslate} returns this
 */
proto.server.MTranslate.prototype.setProxyflagcode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string BackgroundColor = 3;
 * @return {string}
 */
proto.server.MTranslate.prototype.getBackgroundcolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MTranslate} returns this
 */
proto.server.MTranslate.prototype.setBackgroundcolor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createtime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    starttime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    expirationtime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    orderstate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    paymentstate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    mproduct: (f = msg.getMproduct()) && proto.server.MProduct.toObject(includeInstance, f),
    mpaymentpackage: (f = msg.getMpaymentpackage()) && proto.server.MPaymentPackage.toObject(includeInstance, f),
    paymentmethodcodesuss: jspb.Message.getFieldWithDefault(msg, 10, 0),
    username: jspb.Message.getFieldWithDefault(msg, 11, ""),
    stripeinfo: (f = msg.getStripeinfo()) && proto.server.StripeInfo.toObject(includeInstance, f),
    botsession: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MOrder}
 */
proto.server.MOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MOrder;
  return proto.server.MOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MOrder}
 */
proto.server.MOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatetime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStarttime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationtime(value);
      break;
    case 5:
      var value = /** @type {!proto.server.OrderState} */ (reader.readEnum());
      msg.setOrderstate(value);
      break;
    case 6:
      var value = /** @type {!proto.server.PaymentState} */ (reader.readEnum());
      msg.setPaymentstate(value);
      break;
    case 7:
      var value = new proto.server.MProduct;
      reader.readMessage(value,proto.server.MProduct.deserializeBinaryFromReader);
      msg.setMproduct(value);
      break;
    case 8:
      var value = new proto.server.MPaymentPackage;
      reader.readMessage(value,proto.server.MPaymentPackage.deserializeBinaryFromReader);
      msg.setMpaymentpackage(value);
      break;
    case 10:
      var value = /** @type {!proto.server.PaymentMethodCode} */ (reader.readEnum());
      msg.setPaymentmethodcodesuss(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 12:
      var value = new proto.server.StripeInfo;
      reader.readMessage(value,proto.server.StripeInfo.deserializeBinaryFromReader);
      msg.setStripeinfo(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBotsession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatetime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStarttime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getExpirationtime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOrderstate();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPaymentstate();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getMproduct();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.server.MProduct.serializeBinaryToWriter
    );
  }
  f = message.getMpaymentpackage();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.server.MPaymentPackage.serializeBinaryToWriter
    );
  }
  f = message.getPaymentmethodcodesuss();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStripeinfo();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.server.StripeInfo.serializeBinaryToWriter
    );
  }
  f = message.getBotsession();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string Id = 1;
 * @return {string}
 */
proto.server.MOrder.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MOrder} returns this
 */
proto.server.MOrder.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 CreateTime = 2;
 * @return {number}
 */
proto.server.MOrder.prototype.getCreatetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MOrder} returns this
 */
proto.server.MOrder.prototype.setCreatetime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 StartTime = 3;
 * @return {number}
 */
proto.server.MOrder.prototype.getStarttime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MOrder} returns this
 */
proto.server.MOrder.prototype.setStarttime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 ExpirationTime = 4;
 * @return {number}
 */
proto.server.MOrder.prototype.getExpirationtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MOrder} returns this
 */
proto.server.MOrder.prototype.setExpirationtime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional OrderState OrderState = 5;
 * @return {!proto.server.OrderState}
 */
proto.server.MOrder.prototype.getOrderstate = function() {
  return /** @type {!proto.server.OrderState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.server.OrderState} value
 * @return {!proto.server.MOrder} returns this
 */
proto.server.MOrder.prototype.setOrderstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional PaymentState PaymentState = 6;
 * @return {!proto.server.PaymentState}
 */
proto.server.MOrder.prototype.getPaymentstate = function() {
  return /** @type {!proto.server.PaymentState} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.server.PaymentState} value
 * @return {!proto.server.MOrder} returns this
 */
proto.server.MOrder.prototype.setPaymentstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional MProduct MProduct = 7;
 * @return {?proto.server.MProduct}
 */
proto.server.MOrder.prototype.getMproduct = function() {
  return /** @type{?proto.server.MProduct} */ (
    jspb.Message.getWrapperField(this, proto.server.MProduct, 7));
};


/**
 * @param {?proto.server.MProduct|undefined} value
 * @return {!proto.server.MOrder} returns this
*/
proto.server.MOrder.prototype.setMproduct = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.MOrder} returns this
 */
proto.server.MOrder.prototype.clearMproduct = function() {
  return this.setMproduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.MOrder.prototype.hasMproduct = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional MPaymentPackage MPaymentPackage = 8;
 * @return {?proto.server.MPaymentPackage}
 */
proto.server.MOrder.prototype.getMpaymentpackage = function() {
  return /** @type{?proto.server.MPaymentPackage} */ (
    jspb.Message.getWrapperField(this, proto.server.MPaymentPackage, 8));
};


/**
 * @param {?proto.server.MPaymentPackage|undefined} value
 * @return {!proto.server.MOrder} returns this
*/
proto.server.MOrder.prototype.setMpaymentpackage = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.MOrder} returns this
 */
proto.server.MOrder.prototype.clearMpaymentpackage = function() {
  return this.setMpaymentpackage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.MOrder.prototype.hasMpaymentpackage = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional PaymentMethodCode PaymentMethodCodeSuss = 10;
 * @return {!proto.server.PaymentMethodCode}
 */
proto.server.MOrder.prototype.getPaymentmethodcodesuss = function() {
  return /** @type {!proto.server.PaymentMethodCode} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.server.PaymentMethodCode} value
 * @return {!proto.server.MOrder} returns this
 */
proto.server.MOrder.prototype.setPaymentmethodcodesuss = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string UserName = 11;
 * @return {string}
 */
proto.server.MOrder.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MOrder} returns this
 */
proto.server.MOrder.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional StripeInfo StripeInfo = 12;
 * @return {?proto.server.StripeInfo}
 */
proto.server.MOrder.prototype.getStripeinfo = function() {
  return /** @type{?proto.server.StripeInfo} */ (
    jspb.Message.getWrapperField(this, proto.server.StripeInfo, 12));
};


/**
 * @param {?proto.server.StripeInfo|undefined} value
 * @return {!proto.server.MOrder} returns this
*/
proto.server.MOrder.prototype.setStripeinfo = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.MOrder} returns this
 */
proto.server.MOrder.prototype.clearStripeinfo = function() {
  return this.setStripeinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.MOrder.prototype.hasStripeinfo = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string BotSession = 13;
 * @return {string}
 */
proto.server.MOrder.prototype.getBotsession = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MOrder} returns this
 */
proto.server.MOrder.prototype.setBotsession = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MProxyPlan.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MProxyPlan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MProxyPlan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MProxyPlan.toObject = function(includeInstance, msg) {
  var f, obj = {
    instancecountraw: jspb.Message.getFieldWithDefault(msg, 2, 0),
    instancecountcurrent: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timelength: jspb.Message.getFieldWithDefault(msg, 4, 0),
    traffic: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MProxyPlan}
 */
proto.server.MProxyPlan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MProxyPlan;
  return proto.server.MProxyPlan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MProxyPlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MProxyPlan}
 */
proto.server.MProxyPlan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInstancecountraw(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInstancecountcurrent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimelength(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTraffic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MProxyPlan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MProxyPlan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MProxyPlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MProxyPlan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstancecountraw();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getInstancecountcurrent();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTimelength();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getTraffic();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional int64 InstanceCountRaw = 2;
 * @return {number}
 */
proto.server.MProxyPlan.prototype.getInstancecountraw = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MProxyPlan} returns this
 */
proto.server.MProxyPlan.prototype.setInstancecountraw = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 InstanceCountCurrent = 3;
 * @return {number}
 */
proto.server.MProxyPlan.prototype.getInstancecountcurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MProxyPlan} returns this
 */
proto.server.MProxyPlan.prototype.setInstancecountcurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 TimeLength = 4;
 * @return {number}
 */
proto.server.MProxyPlan.prototype.getTimelength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MProxyPlan} returns this
 */
proto.server.MProxyPlan.prototype.setTimelength = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 Traffic = 6;
 * @return {number}
 */
proto.server.MProxyPlan.prototype.getTraffic = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MProxyPlan} returns this
 */
proto.server.MProxyPlan.prototype.setTraffic = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MTrafficPlan.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MTrafficPlan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MTrafficPlan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MTrafficPlan.toObject = function(includeInstance, msg) {
  var f, obj = {
    alreadyused: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    usedtime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    traffic: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MTrafficPlan}
 */
proto.server.MTrafficPlan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MTrafficPlan;
  return proto.server.MTrafficPlan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MTrafficPlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MTrafficPlan}
 */
proto.server.MTrafficPlan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlreadyused(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsedtime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTraffic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MTrafficPlan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MTrafficPlan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MTrafficPlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MTrafficPlan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlreadyused();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getUsedtime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTraffic();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional bool AlreadyUsed = 2;
 * @return {boolean}
 */
proto.server.MTrafficPlan.prototype.getAlreadyused = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.MTrafficPlan} returns this
 */
proto.server.MTrafficPlan.prototype.setAlreadyused = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int64 UsedTime = 3;
 * @return {number}
 */
proto.server.MTrafficPlan.prototype.getUsedtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MTrafficPlan} returns this
 */
proto.server.MTrafficPlan.prototype.setUsedtime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 Traffic = 4;
 * @return {number}
 */
proto.server.MTrafficPlan.prototype.getTraffic = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MTrafficPlan} returns this
 */
proto.server.MTrafficPlan.prototype.setTraffic = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MUserProxy.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MUserProxy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MUserProxy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MUserProxy.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quota: jspb.Message.getFieldWithDefault(msg, 2, 0),
    download: jspb.Message.getFieldWithDefault(msg, 3, 0),
    upload: jspb.Message.getFieldWithDefault(msg, 4, 0),
    exptime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalexptime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    enablecheck: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    tokenraw: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MUserProxy}
 */
proto.server.MUserProxy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MUserProxy;
  return proto.server.MUserProxy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MUserProxy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MUserProxy}
 */
proto.server.MUserProxy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuota(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDownload(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpload(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExptime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalexptime(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnablecheck(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTokenraw(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MUserProxy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MUserProxy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MUserProxy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MUserProxy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuota();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDownload();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getUpload();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getExptime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTotalexptime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getEnablecheck();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getTokenraw();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string Token = 1;
 * @return {string}
 */
proto.server.MUserProxy.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MUserProxy} returns this
 */
proto.server.MUserProxy.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 Quota = 2;
 * @return {number}
 */
proto.server.MUserProxy.prototype.getQuota = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MUserProxy} returns this
 */
proto.server.MUserProxy.prototype.setQuota = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 Download = 3;
 * @return {number}
 */
proto.server.MUserProxy.prototype.getDownload = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MUserProxy} returns this
 */
proto.server.MUserProxy.prototype.setDownload = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 Upload = 4;
 * @return {number}
 */
proto.server.MUserProxy.prototype.getUpload = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MUserProxy} returns this
 */
proto.server.MUserProxy.prototype.setUpload = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 ExpTime = 5;
 * @return {number}
 */
proto.server.MUserProxy.prototype.getExptime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MUserProxy} returns this
 */
proto.server.MUserProxy.prototype.setExptime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 TotalExpTime = 6;
 * @return {number}
 */
proto.server.MUserProxy.prototype.getTotalexptime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MUserProxy} returns this
 */
proto.server.MUserProxy.prototype.setTotalexptime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool EnableCheck = 7;
 * @return {boolean}
 */
proto.server.MUserProxy.prototype.getEnablecheck = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.MUserProxy} returns this
 */
proto.server.MUserProxy.prototype.setEnablecheck = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string TokenRaw = 8;
 * @return {string}
 */
proto.server.MUserProxy.prototype.getTokenraw = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MUserProxy} returns this
 */
proto.server.MUserProxy.prototype.setTokenraw = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.MProduct.repeatedFields_ = [14,5,15,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 8, ""),
    content: jspb.Message.getFieldWithDefault(msg, 9, ""),
    backgroundcolor: jspb.Message.getFieldWithDefault(msg, 10, ""),
    imageurl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    localimagename: jspb.Message.getFieldWithDefault(msg, 12, ""),
    status: jspb.Message.getFieldWithDefault(msg, 13, 0),
    mpaymentpackageidlistList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    mpaymentmethodidlistList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    mpaymentpackagelistList: jspb.Message.toObjectList(msg.getMpaymentpackagelistList(),
    proto.server.MPaymentPackage.toObject, includeInstance),
    mpaymentmethodlistList: jspb.Message.toObjectList(msg.getMpaymentmethodlistList(),
    proto.server.MPaymentMethod.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MProduct}
 */
proto.server.MProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MProduct;
  return proto.server.MProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MProduct}
 */
proto.server.MProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundcolor(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalimagename(value);
      break;
    case 13:
      var value = /** @type {!proto.server.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addMpaymentpackageidlist(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addMpaymentmethodidlist(value);
      break;
    case 15:
      var value = new proto.server.MPaymentPackage;
      reader.readMessage(value,proto.server.MPaymentPackage.deserializeBinaryFromReader);
      msg.addMpaymentpackagelist(value);
      break;
    case 6:
      var value = new proto.server.MPaymentMethod;
      reader.readMessage(value,proto.server.MPaymentMethod.deserializeBinaryFromReader);
      msg.addMpaymentmethodlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBackgroundcolor();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLocalimagename();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getMpaymentpackageidlistList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getMpaymentmethodidlistList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getMpaymentpackagelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.server.MPaymentPackage.serializeBinaryToWriter
    );
  }
  f = message.getMpaymentmethodlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.server.MPaymentMethod.serializeBinaryToWriter
    );
  }
};


/**
 * optional string Id = 1;
 * @return {string}
 */
proto.server.MProduct.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MProduct} returns this
 */
proto.server.MProduct.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Title = 4;
 * @return {string}
 */
proto.server.MProduct.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MProduct} returns this
 */
proto.server.MProduct.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string SubTitle = 8;
 * @return {string}
 */
proto.server.MProduct.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MProduct} returns this
 */
proto.server.MProduct.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string Content = 9;
 * @return {string}
 */
proto.server.MProduct.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MProduct} returns this
 */
proto.server.MProduct.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string BackGroundColor = 10;
 * @return {string}
 */
proto.server.MProduct.prototype.getBackgroundcolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MProduct} returns this
 */
proto.server.MProduct.prototype.setBackgroundcolor = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string ImageUrl = 11;
 * @return {string}
 */
proto.server.MProduct.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MProduct} returns this
 */
proto.server.MProduct.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string LocalImageName = 12;
 * @return {string}
 */
proto.server.MProduct.prototype.getLocalimagename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MProduct} returns this
 */
proto.server.MProduct.prototype.setLocalimagename = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional Status Status = 13;
 * @return {!proto.server.Status}
 */
proto.server.MProduct.prototype.getStatus = function() {
  return /** @type {!proto.server.Status} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.server.Status} value
 * @return {!proto.server.MProduct} returns this
 */
proto.server.MProduct.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * repeated string MPaymentPackageIdList = 14;
 * @return {!Array<string>}
 */
proto.server.MProduct.prototype.getMpaymentpackageidlistList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.server.MProduct} returns this
 */
proto.server.MProduct.prototype.setMpaymentpackageidlistList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.server.MProduct} returns this
 */
proto.server.MProduct.prototype.addMpaymentpackageidlist = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.MProduct} returns this
 */
proto.server.MProduct.prototype.clearMpaymentpackageidlistList = function() {
  return this.setMpaymentpackageidlistList([]);
};


/**
 * repeated string MPaymentMethodIdList = 5;
 * @return {!Array<string>}
 */
proto.server.MProduct.prototype.getMpaymentmethodidlistList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.server.MProduct} returns this
 */
proto.server.MProduct.prototype.setMpaymentmethodidlistList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.server.MProduct} returns this
 */
proto.server.MProduct.prototype.addMpaymentmethodidlist = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.MProduct} returns this
 */
proto.server.MProduct.prototype.clearMpaymentmethodidlistList = function() {
  return this.setMpaymentmethodidlistList([]);
};


/**
 * repeated MPaymentPackage MPaymentPackageList = 15;
 * @return {!Array<!proto.server.MPaymentPackage>}
 */
proto.server.MProduct.prototype.getMpaymentpackagelistList = function() {
  return /** @type{!Array<!proto.server.MPaymentPackage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.server.MPaymentPackage, 15));
};


/**
 * @param {!Array<!proto.server.MPaymentPackage>} value
 * @return {!proto.server.MProduct} returns this
*/
proto.server.MProduct.prototype.setMpaymentpackagelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.server.MPaymentPackage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.server.MPaymentPackage}
 */
proto.server.MProduct.prototype.addMpaymentpackagelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.server.MPaymentPackage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.MProduct} returns this
 */
proto.server.MProduct.prototype.clearMpaymentpackagelistList = function() {
  return this.setMpaymentpackagelistList([]);
};


/**
 * repeated MPaymentMethod MPaymentMethodList = 6;
 * @return {!Array<!proto.server.MPaymentMethod>}
 */
proto.server.MProduct.prototype.getMpaymentmethodlistList = function() {
  return /** @type{!Array<!proto.server.MPaymentMethod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.server.MPaymentMethod, 6));
};


/**
 * @param {!Array<!proto.server.MPaymentMethod>} value
 * @return {!proto.server.MProduct} returns this
*/
proto.server.MProduct.prototype.setMpaymentmethodlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.server.MPaymentMethod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.server.MPaymentMethod}
 */
proto.server.MProduct.prototype.addMpaymentmethodlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.server.MPaymentMethod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.MProduct} returns this
 */
proto.server.MProduct.prototype.clearMpaymentmethodlistList = function() {
  return this.setMpaymentmethodlistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MProxyNode.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MProxyNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MProxyNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MProxyNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    ipaddr: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rank: jspb.Message.getFieldWithDefault(msg, 3, 0),
    content: jspb.Message.getFieldWithDefault(msg, 4, ""),
    enable: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    lastupdate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    traffictimes: jspb.Message.getFieldWithDefault(msg, 7, 0),
    hostname: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MProxyNode}
 */
proto.server.MProxyNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MProxyNode;
  return proto.server.MProxyNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MProxyNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MProxyNode}
 */
proto.server.MProxyNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpaddr(value);
      break;
    case 2:
      var value = /** @type {!proto.server.ProxyNodeStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRank(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastupdate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTraffictimes(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MProxyNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MProxyNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MProxyNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MProxyNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIpaddr();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEnable();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getLastupdate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTraffictimes();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getHostname();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string IpAddr = 1;
 * @return {string}
 */
proto.server.MProxyNode.prototype.getIpaddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MProxyNode} returns this
 */
proto.server.MProxyNode.prototype.setIpaddr = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ProxyNodeStatus Status = 2;
 * @return {!proto.server.ProxyNodeStatus}
 */
proto.server.MProxyNode.prototype.getStatus = function() {
  return /** @type {!proto.server.ProxyNodeStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.server.ProxyNodeStatus} value
 * @return {!proto.server.MProxyNode} returns this
 */
proto.server.MProxyNode.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 rank = 3;
 * @return {number}
 */
proto.server.MProxyNode.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MProxyNode} returns this
 */
proto.server.MProxyNode.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string Content = 4;
 * @return {string}
 */
proto.server.MProxyNode.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MProxyNode} returns this
 */
proto.server.MProxyNode.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool Enable = 5;
 * @return {boolean}
 */
proto.server.MProxyNode.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.MProxyNode} returns this
 */
proto.server.MProxyNode.prototype.setEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int64 LastUpdate = 6;
 * @return {number}
 */
proto.server.MProxyNode.prototype.getLastupdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MProxyNode} returns this
 */
proto.server.MProxyNode.prototype.setLastupdate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 TrafficTimes = 7;
 * @return {number}
 */
proto.server.MProxyNode.prototype.getTraffictimes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MProxyNode} returns this
 */
proto.server.MProxyNode.prototype.setTraffictimes = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string HostName = 8;
 * @return {string}
 */
proto.server.MProxyNode.prototype.getHostname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MProxyNode} returns this
 */
proto.server.MProxyNode.prototype.setHostname = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MData.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MData.toObject = function(includeInstance, msg) {
  var f, obj = {
    datatype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dns: (f = msg.getDns()) && proto.server.Dns.toObject(includeInstance, f),
    mnodemapMap: (f = msg.getMnodemapMap()) ? f.toObject(includeInstance, proto.server.MNode.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MData}
 */
proto.server.MData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MData;
  return proto.server.MData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MData}
 */
proto.server.MData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.server.DataType} */ (reader.readEnum());
      msg.setDatatype(value);
      break;
    case 2:
      var value = new proto.server.Dns;
      reader.readMessage(value,proto.server.Dns.deserializeBinaryFromReader);
      msg.setDns(value);
      break;
    case 3:
      var value = msg.getMnodemapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.server.MNode.deserializeBinaryFromReader, "", new proto.server.MNode());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatatype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDns();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.server.Dns.serializeBinaryToWriter
    );
  }
  f = message.getMnodemapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.server.MNode.serializeBinaryToWriter);
  }
};


/**
 * optional DataType DataType = 1;
 * @return {!proto.server.DataType}
 */
proto.server.MData.prototype.getDatatype = function() {
  return /** @type {!proto.server.DataType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.server.DataType} value
 * @return {!proto.server.MData} returns this
 */
proto.server.MData.prototype.setDatatype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Dns Dns = 2;
 * @return {?proto.server.Dns}
 */
proto.server.MData.prototype.getDns = function() {
  return /** @type{?proto.server.Dns} */ (
    jspb.Message.getWrapperField(this, proto.server.Dns, 2));
};


/**
 * @param {?proto.server.Dns|undefined} value
 * @return {!proto.server.MData} returns this
*/
proto.server.MData.prototype.setDns = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.MData} returns this
 */
proto.server.MData.prototype.clearDns = function() {
  return this.setDns(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.MData.prototype.hasDns = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * map<string, MNode> MNodeMap = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.server.MNode>}
 */
proto.server.MData.prototype.getMnodemapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.server.MNode>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.server.MNode));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.server.MData} returns this
 */
proto.server.MData.prototype.clearMnodemapMap = function() {
  this.getMnodemapMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.Dns.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.Dns.prototype.toObject = function(opt_includeInstance) {
  return proto.server.Dns.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.Dns} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.Dns.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dnsnameList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.Dns}
 */
proto.server.Dns.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.Dns;
  return proto.server.Dns.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.Dns} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.Dns}
 */
proto.server.Dns.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addDnsname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.Dns.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.Dns.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.Dns} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.Dns.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDnsnameList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string ProjectName = 2;
 * @return {string}
 */
proto.server.Dns.prototype.getProjectname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.Dns} returns this
 */
proto.server.Dns.prototype.setProjectname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string DnsName = 3;
 * @return {!Array<string>}
 */
proto.server.Dns.prototype.getDnsnameList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.server.Dns} returns this
 */
proto.server.Dns.prototype.setDnsnameList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.server.Dns} returns this
 */
proto.server.Dns.prototype.addDnsname = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.Dns} returns this
 */
proto.server.Dns.prototype.clearDnsnameList = function() {
  return this.setDnsnameList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.MNode.prototype.toObject = function(opt_includeInstance) {
  return proto.server.MNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.MNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    ipaddr: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastupdate: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.MNode}
 */
proto.server.MNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.MNode;
  return proto.server.MNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.MNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.MNode}
 */
proto.server.MNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpaddr(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastupdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.MNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.MNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.MNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.MNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIpaddr();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastupdate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string IpAddr = 1;
 * @return {string}
 */
proto.server.MNode.prototype.getIpaddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.MNode} returns this
 */
proto.server.MNode.prototype.setIpaddr = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 LastUpdate = 6;
 * @return {number}
 */
proto.server.MNode.prototype.getLastupdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.MNode} returns this
 */
proto.server.MNode.prototype.setLastupdate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * @enum {number}
 */
proto.server.Status = {
  STATUSUNDEFINE: 0,
  STATUSUNAVAILABLE: -1,
  STATUSAVAILABLE: 1
};

/**
 * @enum {number}
 */
proto.server.PaymentMethodCode = {
  PAYMENTMETHODUNDEFINE: 0,
  PAYMENTMETHODACCOUNTBALANCE: 1,
  PAYMENTMETHODONLINESTRIPE: 2
};

/**
 * @enum {number}
 */
proto.server.BalanceType = {
  USERBALANCEUNDEFINE: 0,
  USERBALANCEADD: 1,
  USERBALANCESUB: 2
};

/**
 * @enum {number}
 */
proto.server.UserType = {
  USERTYPEUNDEFINE: 0,
  USERTYPEGENERALUSER: 1,
  USERTYPEBOT: 2
};

/**
 * @enum {number}
 */
proto.server.UserPaymentCode = {
  USERPAYMENTCODEUNDEFINE: 0,
  USERPAYMENTCODESUSS: 1,
  USERPAYMENTCODESTAGETWOWEBVIEWSTRIPE: 2
};

/**
 * @enum {number}
 */
proto.server.PaymentState = {
  PAYMENTSTATEERR: 0,
  PAYMENTSTATENONPAY: 1,
  PAYMENTSTATEPAID: 2
};

/**
 * @enum {number}
 */
proto.server.OrderState = {
  ORDERSTATEERR: 0,
  ORDERSTATEDOSENOTNEEDTOBEPROCESSED: 1,
  ORDERSTATENEEDTOBEPROCESSED: 2,
  ORDERSTATEPROCESSING: 3,
  ORDERSTATEPROCESSEDSUSS: 4,
  ORDERSTATEPROCESSEDFAIL: 5,
  ORDERSTATEUSERCANCEL: 6,
  ORDERSTATEADMINCANCEL: 7
};

/**
 * @enum {number}
 */
proto.server.MProductPlanType = {
  MPRODUCTPLANTYPEUNDEFINE: 0,
  MPRODUCTPLANTYPEMPROXYPLAN: 1,
  MPRODUCTPLANTYPEMTRAFFICPLAN: 2
};

/**
 * @enum {number}
 */
proto.server.ProxyNodeStatus = {
  PROXYNODESTATUSUNDEFINE: 0,
  PROXYNODESTATUSOFFLINE: 1,
  PROXYNODESTATUSONLINE: 2
};

/**
 * @enum {number}
 */
proto.server.DataType = {
  DATATYPEUNDEFINE: 0,
  DATATYPEMNODEMASTER: 1
};

goog.object.extend(exports, proto.server);
